/**
 * #Created with WebStorm.
 * User: mattschleindl
 * Date: 7/28/17
 * Time: 6:28 AM
 * To change this template use File | Settings | File Templates.
 */

function userInformation() {
  this.title = null;
  this.first_name = null;
  this.last_name = null;
  this.middle_name = null;
  this.suffix = null;
  this.phone = null;
  this.email = null;
  this.address = {
    street_1: null,
    street_2: null,
    city: null,
    state: null,
    zip: null,
    country: 'United States', // see person-information.component
    countryCode: null
  }
}


function userState() {
  this.isLoggedIn = null; // {true|false}
  this.hash = null;
  this.emplId = null;
  this.pennKey = null;
  this.pennId = null;
  this.userInformation = new userInformation();
  this.action = null; //{true|false|promise};
  this.hashResponse = null;
  this.atlasResponse = null;
  this.nodeUserResponse = null;
  this.pcomResponse = null;
  this.error = null;
}

function populateUserInformationFromAtlas(userState, dataLoader) {
  //var userInformation = userState.personalDetailsComponent.userInformation;

  var atlas = userState.atlasResponse;

  userState.userInformation.first_name = atlas.first_name;
  userState.userInformation.last_name = atlas.last_name;
  userState.userInformation.middle_name = atlas.middle_name;
  userState.userInformation.title = atlas.name_prefix;
  userState.userInformation.suffix = atlas.name_suffix;
  userState.userInformation.phone = dataLoader.filterPhoneCharacters(atlas.phone);
  userState.userInformation.email = atlas.email_addr;
  userState.userInformation.address = {
    street_1: atlas.address1,
    street_2: atlas.address2,
    city: atlas.city,
    state: atlas.state,
    zip: atlas.postal,
  };
  let country = dataLoader.getCountryForCode(atlas.country);
  let stateRecord = dataLoader.getStateByCodeAndCountry(atlas.state, country);

  if ( stateRecord ) {
    userState.userInformation.address.state = stateRecord.state_name;
    userState.userInformation.address.stateCode = stateRecord.state_code;
  }
  if (country) {
    userState.userInformation.address.country = country.country_name;
    userState.userInformation.address.countryCode = country.country_code;
  }
}


module.exports = {
  userInformation: userInformation,
  userState: userState,
  populateUserInformationFromAtlas: populateUserInformationFromAtlas
};
