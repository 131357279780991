/**
 * Created by mkreddy on 7/11/17.
 */

'use strict';

/**
 * From the /donation_school service.
 *
 * Setting all fields to null to aid in testing - make sure
 * json has correct fields when copied over.
 */
export default class DonationSchool {

  /** @type {number} */
  hibernate_id = -1;

  /** @type {?string} */
  group_code = null;

  /** @type {string} */
  school_code = '';

  /** @type {?string} */
  school_name = null;

  /** @type {?number} */
  list_order = null;

  /** @type {?string} */
  active_ind = null;

  /** @type {?string} */
  is_in_memory_or_honor_of = null;


  /** @type {?string} */
  special_reason_ind = null;

  /** @type {?string} */
  show_joint_gift = null;

  get name() {
    return this.school_name;
  }

  /** Unique id - not sure how stable hibernate_id is. */
  get code() {
    return this.school_code;
  }


}
