import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routes from './fund.routes';
import DonationSpotlightFundAdapter from "../../givingService/DonationSpotlightFundAdapter";
import PersonBean from '../personal-details/personInfoBeans';
import givingCurrency from '../../directives/givingCurrency/givingCurrency.directive';
import ControlParam from "../../givingService/ControlParam";
import {Decimal} from 'decimal.js';
import recaptcha from '../../../components/recaptcha/recaptcha.component';
import _ from "lodash";

export class FundComponent {

  /**
   * @const
   * @type {string}
   */
  GIFT_PAYMENT_FREQ_PARAM = 'PAYMENT_FREQUENCY_V2';

  /**
   * @const
   * @type {string}
   */
  PLEDGE_PAYMENT_FREQ_PARAM = 'PAYMENT_FREQUENCY';


  /**
   * @const
   * @type {string}
   */
  PLEDGE_YEARS_PARAM = 'YEAR_SEQUENCE';

  /**
   * Minimum pledge amount
   * @const
   * @type {!number}
   */
  MIN_AMOUNT = 5;

  MAX_PLEDGE_AMOUNT_LESS_THAN = 25000;

  fundData;

  /**
   * Holds the spotlight information (what you see when you click on the i-icon)
   * @type {!DonationSpotlightFund}
   */
  spotlightFund;

  /** @type {!DataLoader} */
  dataLoader;

  /** Data that will be send to cybersource */
  csData = {};

  /**
   * To prevent multiple submits to cybersource.
   * @type {boolean}
   */
  submitEnabled = true;

  $sessionStorage;

  giftPaymentFrequencies;

  pledgeYears;

  /** @type {CheckoutService} */
  checkout;

  /** @type {GivingService} */
  givingService;

  /** @type {ControlParam[]} */
  currencyControlParams = [];

  /**
   * @type {string}
   */
  stripePublishableKey;

  /**
   * @type {?StripeToken}
   */
  stripeToken = null;

  /**
   * @type {string}
   */
  recaptchaSitekey;

  /**
   * From the grecaptcha script
   * @type {Object}
   */
  grecaptcha;

  /**
   * @type {?string}
   */
  recaptchaToken = null;

  /**
   * @type {?StripePaymentMethod}
   */
  stripePaymentMethod = null;

  /**
   * @type {?StripePaymentIntent}
   */
  stripePaymentIntent = null;

  shouldShowCitizenShip = false;
  shouldShowCitizenShipError = false;

  /*@ngInject*/
  constructor($scope, $location, $http, $stateParams, cartMemory, userData, Util, currencySymbol, dataLoader,
              $sessionStorage, checkout, givingService) {
    this.$location = $location;
    this.$http = $http;
    this.dataLoader = dataLoader;
    this.$stateParams = $stateParams;

    this.cartMemoryService = cartMemory;
    this.timezoneOptions = {
      timezone: '-5'
    };
    this.$scope = $scope;
    this.userDataService = userData;
    this.UtilService = Util;
    this.$sessionStorage = $sessionStorage;

    this.otherGiftsLabelDefault = "Or choose another gift type";

    this.currencySymbolService = currencySymbol;
    this.currency = this.currencySymbolService.get();

    this.formData = {};

    //Amounts continter
    this.amounts = {
      "customAmount": null,
      "amount": null
    };
    //frequency dropdown list and labels and default value.

    this.giftPaymentFrequencies = this.dataLoader.getControlParams(this.GIFT_PAYMENT_FREQ_PARAM);
    this.giftPaymentFrequencies.forEach(
      item => {
        item.id = parseInt(this.cartMemoryService.charCodeToFreqNumber(item.value));
      }
    );

    this.paymentFrequencies = this.dataLoader.getControlParams(this.PLEDGE_PAYMENT_FREQ_PARAM);
    this.paymentFrequencies.forEach(
      item => {
        item.id = parseInt(this.cartMemoryService.charCodeToFreqNumber(item.value));
      }
    );

    this.pledgeYears = this.dataLoader.getControlParams(this.PLEDGE_YEARS_PARAM);
    this.pledgeYears.forEach(
      item => {
        item.name = item.description;
        item.valueNum = parseInt(item.value);
      }
    );

    // this.pickerId = 0;
    // this.otherGiftPickerId = 0;
    // GIVING-254: default to first item in list
    this.pickerId = this.giftPaymentFrequencies[0].id;
    this.otherGiftPickerId = this.paymentFrequencies[0].id;


    //Set inital frequency 3 = once
    this.frequency(3);

    //Pre-Population of form
    if (this.$stateParams.amount != null) {
      this.amounts.customAmount = parseFloat(this.$stateParams.amount);
    }

    if (this.$stateParams.type != null) {
      this.frequency(parseInt(this.$stateParams.type));
    }

    if (this.$stateParams.emplid != null) {
      this.formData.emplid = this.$stateParams.emplid;
    }

    if (this.$stateParams.appeal != null) {
      this.formData.appeal = this.$stateParams.appeal;
    } else if (this.$sessionStorage.appeal) {
      // WR-419, let original logic take precedence
      this.formData.appeal = this.$sessionStorage.appeal;
    }

    if (this.$stateParams.fastStart === 'simpleForm') {
      this.fastStart = true;

      this.submitText = "Donate now";

      // GIVING-286: empty the cart for simpleForm
      this.cartMemoryService.empty();
    } else {
      this.submitText = "Add to basket and continue";
    }

    this.checkout = checkout;

    this.givingService = givingService;

  }//end constructor

  //General functions
  frequency = (id) => {
    this.formData.type = id;
    this.otherGiftsLabel = this.otherGiftsLabelDefault;
    this.formData.otherGift = null;
    if (id <= 2) {
      this.pickerId = id;
    }
    this.formData.frequency = id;

    // SD: to support stripe GIVING-410
    if (id !== 3 && _.get(this, 'fundData.amounts')) {
      // only one-time gifts can change currencies so
      // if it's not a one-time gift, reset the currency
      this.resetCurrency();
    }
  };

  otherGiftFrequency = (id) => {
    this.otherGiftPickerId = id;
    this.formData.frequency = id;


    // SD: type shouldn't change if frequency changes
    // this.formData.type = 4;

  };

  otherGiftTypes = (name, id) => {
    this.formData.type = id;
    // this.formData.special_processing = 'F';
    this.otherGiftsLabel = name;
    this.formData.otherGift = {
      "id": id,
      "name": name
    };

    // SD: allow for either type of pledge
    if (id === 4 || id === 6) {
      // SD: autoCharge should be determined by type === 4, not be orthogonal to pledge type
      // this.formData.otherGift.autoCharge = true;

      // SD: default to first item in list
      this.formData.frequency = this.paymentFrequencies[0].id;
      this.formData.otherGift.timeSpan = this.pledgeYears[0];
    }

    if (id === 7) {
      // DAF's are one-time gifts - doesn't matter but let's be consistent
      this.formData.frequency = 3;
    }

    // SD: to support stripe GIVING-410
    this.resetCurrency();
  };

  resetCurrency() {
    let previousCurrencyCode = this.currencySymbolService.get().code;
    this.currencySymbolService.reset();
    this.changeCurrency(
      this.currencySymbolService.get().symbol,
      'USD',
      previousCurrencyCode
    );

  }

  isPledgeToHigh() {
    return !!(this.formData
      && this.formData.otherGift
      && this.formData.otherGift.pledge
      && this.formData.otherGift.pledge.totalValue
      && this.formData.otherGift.pledge.totalValue >= this.getMaxPledgeAmountLessThan());
  }


  //NOTE: Refactor dropdown to work better with multiple on a pages... this is daft! High prioity! Grant Bajere
  dropdownSelected = (item, model) => {

    if (model === 'timeSpan') {
      if (angular.isDefined(this.formData.otherGift)) {
        this.formData.otherGift.timeSpan = item;
      } else {
        this.formData.otherGift.timeSpan = item;
      }
    }

    if (model === 'pledge') {
      if (angular.isDefined(this.formData.otherGift) && angular.isDefined(this.formData.otherGift.pledge)) {
        this.formData.otherGift.pledge.frequency = item.name;
      } else {
        this.formData.otherGift.pledge = {
          "frequency": item.name
        };
      }
    }
  };

  getEffectiveAmounts(school_code, fund_code, amounts) {
    let sortedAmounts = amounts.filter((amount) => {
      return amount.school_code == school_code && amount.fund_code == fund_code;
    });

    sortedAmounts = sortedAmounts.length > 1 ? sortedAmounts : amounts.filter((amount) => {
      return amount.school_code == 'DEF' && amount.fund_code == '00000';
    });
    return _.cloneDeep(sortedAmounts);
  }

  callHash = (userState) => {
    return this.$http.get('/api/hash/' + userState.encodedHash);
  };

  callAtlasEmplId = (userState) => {
    return this.$http.get('/api/giving_donor_pers_info/emplid/');
  };

  $onInit() {
    this.shouldShowCitizenShip = true;
    this.userInformation = null;
    this.dateMessage = false;
    let userState = new PersonBean.userState();
    this.updateCustomAmount();
    if (this.$stateParams.hash) {
      userState.hash = this.$stateParams.hash;
      userState.encodedHash = encodeURIComponent(userState.hash);
      // idea here is to get it or fail, populate personal info or not
      this.callHash(userState)
        .then((response) => {
          userState.hashResponse = response.data;
          return Promise.resolve(userState);
        })
        .then((_userState) => {
          return this.callAtlasEmplId(userState);
        })
        .then((response) => {
          userState.atlasResponse = response.data;

          PersonBean.populateUserInformationFromAtlas(userState, this.dataLoader);

          this.userDataService.set(userState.userInformation);
          this.userInformation = userState.userInformation;
          return Promise.resolve(userState);
        })
        .catch((err) => {
          userState.error = err;
          // The UI (fund.html) relies on having userInformation being non-null at this point,
          // even if we don't have any info.  So, explicitly make it non-null here
          this.userInformation = this.userInformation ? this.userInformation : {};
          userState.userInformation = this.userInformation;
          return Promise.resolve(userState);
        });
      // get hash - will populate server session
      // get emplid - will user server session to retrieve personal info
    } else {
      // The UI (fund.html) relies on having userInformation being non-null at this point,
      // even if we don't have any info.  So, explicitly make it non-null here
      this.userInformation = this.userInformation ? this.userInformation : {};
      userState.userInformation = this.userInformation;
    }

    this.countries = this.dataLoader.countryCodes
      .map(item => {
        item.name = item.country_name;
        item.code = item.country_code;
        return item;
      });

    this.states = this.dataLoader.stateCodes
      .map(item => {
        item.name = item.state_name;
        item.code = item.state_code;
        return item;
      });

    //Reject if no fund or program information is in url
    if (this.$stateParams.fund === null && this.$stateParams.program != null) {

      this.schoolRecord = this.dataLoader.findSchoolBySchoolCode(this.$stateParams.program);

      let area = this.schoolRecord.group_code;

      let url = '/find-fund?program=' + this.$stateParams.program +
        '&area=' + this.schoolRecord.group_code;

      if (this.$stateParams.amount) {
        url = url.concat('&amount=' + this.$stateParams.amount);
      }

      if (this.$stateParams.appeal) {
        url = url.concat('&appeal=' + this.$stateParams.appeal);
      }
      this.$location.url(url);
      // this.$location.url('/find-fund?program=' + this.$stateParams.program +
      //   '&area=' + this.schoolRecord.group_code +
      //   '&amount=' + this.$stateParams.amount +
      //   '&appeal=' + this.$stateParams.appeal);
    } else {
      var self = this;
      /** @type {?DonationFund} */
      var fund = this.dataLoader.funds.find(function (_fund) {
        return _fund.fund_code == self.$stateParams.fund && _fund.school_code == self.$stateParams.program;
      });
      // if !fund => ???
      this.schoolRecord = this.dataLoader.findSchoolBySchoolCode(fund.school_code);

      this.spotlightFund =
        new DonationSpotlightFundAdapter(fund, this.dataLoader.donationSpotlightFunds).adapt();

      this.formData.id = fund.hibernate_id;
      this.formData.program_id = fund.school_code;
      this.formData.name = fund.fund_name;
      this.formData.special_processing = fund.special_processing;


      // replace what were class attributes (since JSON strips them)
      fund.name = fund.fund_name;
      fund._id = fund.fund_code;
      fund.program_id = fund.school_code;
      fund.descript = fund.long_description;
      this.fundData = fund;

      this.fundData.amounts = self.getEffectiveAmounts(self.$stateParams.program, self.$stateParams.fund, this.dataLoader.amounts);

      // original code from TB below, a little cryptic, but seems we need to translate our
      // DONATION_FUND boolean fields to Grant's naming conventions
      this.fundData.options = {
        pledge: {
          enabled: true
        },
        honor: {
          enabled: true
        },
        memory: {
          enabled: true
        },
        special: {
          enabled: true
        },
        joint: {
          enabled: true
        },
        employer: {
          enabled: true
        },
        anonymous: {
          enabled: true
        },
        planned: {
          enabled: true
        }
      };

      this.fundData.short_options = {
        pledge: {
          enabled: true
        },
        honor: {
          enabled: true
        },
        memory: {
          enabled: true
        },
        special: {
          enabled: true
        },
        joint: {
          enabled: true
        },
        employer: {
          enabled: true
        },
        anonymous: {
          enabled: true
        },
        planned: {
          enabled: true
        }
      };

      this.fundData.other_gifts = [];
    }

    //UX
    this.otherGiftsLabel = this.otherGiftsLabelDefault;
    this.formData.otherGift = null;

    this.disabled = this.cartMemoryService.status();
    this.setAllowedOptions = () => {
      this.disabled = this.cartMemoryService.status();
    };
    this.$scope.$on("cartChanged", this.setAllowedOptions);


    this.$scope.$watch(angular.bind(this, function () {
      if (angular.isDefined(this.amounts.customAmount) && this.amounts.customAmount !== null) {
        this.formData.amount = this.cartMemoryService.parseFloatWithCommas(this.amounts.customAmount);
      }
    }));

    //UI interactions

    this.clearCustom = () => {
      this.shouldShowCitizenShip = true;
      this.amounts.customAmount = null;
    };

    //Form submit
    this.submit = () => {
      if (
        this.shouldShowCitizenShip
        && (this.userInformation.citizenshipType == null || this.userInformation.citizenShipCountryCode == null)
        && this.fastStart != null
      ) {
        this.shouldShowCitizenShipError = true;
        return false;
      }
      if (this.formData.endDate) {
        var now = new Date(this.formData.endDate);
        this.formData.endDate = now.toLocaleDateString();
        this.formData.startDate = now.toLocaleDateString();
      }
      if (this.fastStart === true) {
        this.cartMemoryService.add(this.formData);
        this.submitEnabled = false; // don't let them hit it more than once
        this.checkout.checkout(
          this.csData,
          this.cartMemoryService,
          this.userInformation,
          this.stripeToken,
          this.recaptchaToken,
          this.stripePaymentMethod,
          this.stripePaymentIntent,
          null
        );
      } else {
        let n = this.cartMemoryService.retrieve();
        if (n.length <= 4) {
          this.cartMemoryService.add(this.formData);
          this.$location.url('/login');
        } else {
          alert('Maximum of 5 funds allowed in one transaction');//Should never be seen, but just in case...
        }
      }

    };

    this.showFundInformation = false;
    this.toggleFundInformation = () => {
      if (this.showFundInformation) {
        this.showFundInformation = false;
      } else {
        this.showFundInformation = true;
      }
    };

    //Date pickers
    this.startDateLabel = new Date();
    this.dateOptions = {
      maxDate: new Date(2099, 1, 1),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    this.altInputFormats = ['M!/d!/yyyy'];

    this.endDateOpen = false;
    this.openEnd = () => {
      this.endDateOpen = true;
    };

    this.prefixNames = this.dataLoader.getControlParams('PREFIX_NAME');
    this.prefixNames = this.prefixNames.map((item) => {
      let newItem = {name: item.description};
      return newItem;
    });
    this.prefixNames = [{name: null}].concat(this.prefixNames);

    // SD: GIVING-350. initialize with this.otherGiftTypes if the type was on the url
    if (this.$stateParams.type != null) {
      let type = parseInt(this.$stateParams.type);
      if (type === 4 || type === 6) {
        this.otherGiftTypes('Pledge', type);
      } else if (type === 5) {
        this.otherGiftTypes('Securities', type);
      } else if (type === 7) {
        this.otherGiftTypes('Donor Advised Fund', type);
      }
    }

    this.currencyControlParams = this.dataLoader.getControlParams(ControlParam.CURRENCY_INDICATOR);
    this.stripePublishableKey = this.dataLoader.stripeConfig.publishableKey;
    this.recaptchaSitekey = this.dataLoader.recaptchaConfig.sitekey;

    this.givingService.getGrecaptcha().then(grecaptcha => {
      this.grecaptcha = grecaptcha;
    });
  }

  getImage() {
    let imageFile = this.UtilService.getImageUrlMobileOrDesktop(
      this.spotlightFund.mobile_image_file,
      this.spotlightFund.image_file
    );
    // GIVING-279
    this.$sessionStorage.backgroundImage = imageFile;
    return imageFile;
  }

  // this was being called by the uib datepicker, seems unnecessary
  // SD: GIVING-318 - using form that was already on the page fixes it and
  // allow end date validation to still work
  validateDate() {
    if (fundForm.endDate.className.includes('invalid'))
      this.dateMessage = true;
    else
      this.dateMessage = false;
  }

  /**
   * GIVING-319 - this is for the case where customAmount goes from populated to null.
   * Other changes are handled in a $watch above. Ideally would refactor
   * to get rid of $watch, but tried that and it caused issues, so leave it.
   */
  updateCustomAmount() {
    // probably works fine without null check, but let's not have it duplicate what the $watch above
    // already handles.
    if (this.amounts.customAmount == null) {
      this.formData.amount = this.amounts.customAmount;
    }
    const threshold = this.dataLoader.getControlParams('CITIZENSHIP_THRESHOLD')[0].value;
    this.shouldShowCitizenShip = parseFloat(this.amounts.customAmount) >= parseInt(threshold);
  }


  checkCurrentCurrency() {
    let foundItems = this.dataLoader.checkCurrentCurrencyItem(this.currency);
    let foundItem = foundItems.find(a => a.parameter === "ZERO_DECIMAL");
    if (!foundItem)
      foundItem = foundItems.find(a => a.parameter === "CURRENCY_INDICATOR");
    this.$sessionStorage.foundItem = foundItem;
    this.cartMemoryService.setFoundItem(foundItem);
    if (foundItem && foundItem.parameter === "ZERO_DECIMAL") {
      return true;
    } else {
      return false;
    }
  }

  handleNumbers(event) {
    if (isNaN(event.key) || event.key === ' ' || event.key === '') {
      event.returnValue = '';
      event.preventDefault();
    }
  };

  /**
   * Should we use Stripe for this donation?
   */
  isStripeCart() {
    return this.UtilService.isStripeCart([this.formData]);
  }

  /**
   * Should we check out with Stripe?
   * @return {boolean}
   */
  useStripeCheckout() {
    return this.UtilService.useStripeCheckout([this.formData], this.userInformation);
  }


  //Handle Decimal Numbers
  handleNumbers(event) {
    if (isNaN(event.key) || event.key === ' ' || event.key === '') {
      event.returnValue = '';
      event.preventDefault();
    }
  };

  stripeSubmit(token) {
    this.stripeToken = token;
    this.submit();
  }

  /**
   * @param {string} currencySymbol
   * @param {string} currencyCode
   * @param {?string} previousCurrencyCode
   */
  changeCurrency(currencySymbol, currencyCode, previousCurrencyCode) {
    this.currencySymbolService.set(this.UtilService.toCurrencySymbol(currencySymbol));
    this.currencySymbolService.setCurrencyCode(currencyCode);
    // Reset to us dollars then convert currency to avoid rounding errors
    let amounts =
      this.getEffectiveAmounts(
        this.$stateParams.program,
        this.$stateParams.fund,
        this.dataLoader.amounts
      );
    for (let i = 0; i < amounts.length; i++) {
      amounts[i].amount =
        this.convertAmountForCurrency(
          amounts[i].amount,
          'USD',
          currencyCode,
          0
        );
    }
    this.fundData.amounts = amounts;
    if (this.amounts.customAmount) {
      let customAmount =
        this.convertAmountForCurrency(
          this.amounts.customAmount,
          previousCurrencyCode,
          currencyCode,
          2
        );
      this.amounts.customAmount = new Decimal(customAmount).toFixed(2);

      if (this.dataLoader.getControlParam('ZERO_DECIMAL', this.currency.code)) {
        this.amounts.customAmount = Math.round(this.amounts.customAmount);
      }
    }
  }

  canChangeCurrency() {
    let canChangeCurrency = false;
    if (this.isStripeCart() && this.cartMemoryService.retrieve().length === 0) {
      canChangeCurrency = true;
    }
    return canChangeCurrency;
  }

  /**
   * @param {?(number | string)} amount
   * @param {?string} fromCurrencyCode
   * @param {string} toCurrencyCode
   * @param {number} decimalPlaces
   * @returns {?number} converted amount
   */
  convertAmountForCurrency(amount, fromCurrencyCode, toCurrencyCode, decimalPlaces) {

    if (amount == null || fromCurrencyCode == null || fromCurrencyCode === toCurrencyCode) {
      return amount;
    }

    let toDollarsExchangeRate = _.get(this.dataLoader.getExchangeRate(fromCurrencyCode), 'currency_amount', null);
    let fromDollarsExchangeRate = _.get(this.dataLoader.getExchangeRate(toCurrencyCode), 'currency_amount', null);
    if (toDollarsExchangeRate == null || fromDollarsExchangeRate == null) {
      return amount;
    }
    let toDollarsExchangeRateDecimal = new Decimal(toDollarsExchangeRate);
    let fromDollarsExchangeRateDecimal = new Decimal(fromDollarsExchangeRate);
    let amountDecimal = new Decimal(amount);

    // first convert to dollars
    amountDecimal = amountDecimal.dividedBy(toDollarsExchangeRateDecimal);

    // now go to the target currency
    amountDecimal = amountDecimal.times(fromDollarsExchangeRateDecimal).toDecimalPlaces(decimalPlaces);
    let amountToCurrency = amountDecimal.toNumber();
    return amountToCurrency;
  }

  getMinAmount() {
    if (this.currency.code === 'USD') {
      return this.MIN_AMOUNT;
    } else {
      let minAmount = this.convertAmountForCurrency(this.MIN_AMOUNT, 'USD', this.currency.code, 2);
      if (this.dataLoader.getControlParam("ZERO_DECIMAL", this.currency.code)) {
        return new Decimal(minAmount).toDP(0, Decimal.ROUND_UP).toNumber();
      }

      return minAmount;
    }
  }

  getMaxPledgeAmountLessThan() {
    return this.MAX_PLEDGE_AMOUNT_LESS_THAN;
  }

  recaptchaCallback(recaptchaToken) {
    this.recaptchaToken = recaptchaToken;
  }

  recaptchaExpiredCallback() {
    this.recaptchaToken = null;
  }

  /**
   * @param {StripePaymentMethod} stripePaymentMethod
   * @param {StripePaymentIntent} stripePaymentIntent
   */
  stripeSubmitPaymentRequest(
    stripePaymentMethod,
    stripePaymentIntent
  ) {
    this.stripePaymentMethod = stripePaymentMethod;
    this.stripePaymentIntent = stripePaymentIntent;
    this.submit();
  }

  getCartCyberSourceAmount() {
    return this.cartMemoryService.getTotalsForCartContents([this.formData]).cyberSourceAmount;
  }

  getStripePaymentIntent(
    amount,
    currency,
  ) {
    return this.givingService.stripePaymentIntent(amount, currency, this.recaptchaToken);
  }

  /**
   * These fund types only allow a single fund in the cart
   * @returns {boolean}
   */
  singleFundGiftType() {
    return (
      this.disabled.recurring === true
      || this.disabled.pledgeRecurring === true
      || this.disabled.donorAdvisedFund === true
    )
  }

  canAddDafGift() {
    return this.cartMemoryService.cartContents.length === 0
  }

  specialProcessing() {
    return this.fundData.special_processing
  }

}

export default angular.module(
  'givingApp.fund',
  [uiRouter, givingCurrency, recaptcha])
  .config(routes)
  .component('fund', {
    template: require('./fund.html'),
    controller: FundComponent,
  })
  .name;
