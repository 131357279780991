/**
 * Created by samd on 6/29/17.
 */

'use strict';

/**
 * From the donation_gifting_tiles/ service.
 *
 * Setting all fields to aid in testing - make sure
 * json has correct fields when copied over.
 *
 * NOTE that unit tests and caching all assume this is a simple
 * JS object, so class methods should be removed.
 */
export default class DonationGiftingTile {

  /** @type {number} */
  hibernate_id = -1;

  /** @type {?string} */
  school_code = null;

  /** @type {?string} */
  image_message = null;

  /** @type {string} */
  active = '';

  /** @type {?string} */
  image_title = null;

  /** @type {?string} */
  desktop_image_file = null;

  /** @type {?string} */
  mobile_image_file = null;

  /** @type {?string} */
  fund_code = null;

  /** @type {?string} */
  list_order = null;

  /** @type {?string} */
  image_type = null;

  /** @type {?string} */
  image_url = null;

  /**
   * This will be added as needed in components - see for example fund-highlight.component.js
   * @type {?string}
   */
  group_code = null;

  /*@ngInject*/
  constructor(Util) {
    this.util = Util;
  }

  // ms: this was commented out, put where its used fund-highlight.component.
  // /**
  //  * Return the type as expected by fund-highlight.
  //  * @return {string}
  //  *
  //  */
  // get type() {
  //   // Logic as per GIVING-92
  //   if (this.school_code && this.fund_code) {
  //     return 'FUND';
  //   }
  //   if (this.school_code && !this.fund_code) {
  //     return 'PROGRAM';
  //   }
  //   if (this.image_url) {
  //     return 'EXTERNAL';
  //   }
  // }

  get program() {
    return this.fund_code;
  }

  // get link() {
  //   return this.image_url;
  // }

  get name() {
    return this.image_title;
  }

  // get image() {
  //   return this.util.getImageUrlMobileOrDesktop(this.mobile_image_file, this.desktop_image_file);
  // }

  // util = null;


}

