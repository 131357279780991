'use strict';

var appName = 'giving';

/**
 * Note: apparently Express is picking up the host from the request, which
 * may be forwarded from a DNS server for rerouting old applications.
 * Net result is that we must consider those forwarded request's hosts
 * to be the same as our real host, otherwise auth checks will not
 * verify the host and no auth will be allowed.
 *
 * @type {{}}
 */
var host = {};
host.local = 'localhost';
host.dev = appName + 'dev.aws.cloud.upenn.edu';
host.test = appName + 'test.aws.cloud.upenn.edu';
// Note that we can simply hardcode the old app name if it differs from ours
host.test_lcf = appName + '-test.apps.upenn.edu';
host.prod = appName + '.aws.cloud.upenn.edu';
host.prod_lcf = appName + '.apps.upenn.edu';


var allowedAuth = {};
allowedAuth[host.local] = [];
allowedAuth[host.dev] = ['saml'];
allowedAuth[host.test] = ['saml'];
allowedAuth[host.test_lcf] = ['saml'];
allowedAuth[host.prod] = ['saml'];
allowedAuth[host.prod_lcf] = ['saml'];


/**
 * Use this to determine whether or not the the authType is allowed on the host.
 * Auth types refer to Passport plugins, which are usually found under server/auth/, eg 'saml' or 'local'
 * On the browser, angular supplies the host via $localhost.host()
 * In Node, this is supplied via req.get('host')
 * @param authType
 * @param host
 * @returns {boolean}
 */
function isAuthTypeAllowedOnHost(authType, host) {
  var index = host.indexOf(':');
  host = index > -1 ? host.substr(0, index) : host;
  var allowedAuthTypes = allowedAuth[host];
  //ms: can't use lodash: var indexOf = allowedAuth ? _.findIndex(allowedAuth, authType) > 0 : -1;
  var indexOf = -1;
  if ( allowedAuthTypes ) {
    for(var i = 0; i < allowedAuthTypes.length; i++) {
      if (authType == allowedAuthTypes[i]) {
        indexOf = i;
        break;
      }
    }
  }
  return indexOf > -1;
}

/**
 * @return root URL this app is running on
 */
function getRootUrl() {
  if (process.env.NODE_ENV != 'production') {
    return 'http://localhost:3000'
  }
  var thisHost = host.dev;
  if (process.env.AWS_APPS_ENV == 'test') {
    thisHost = host.test;
  } else if (process.env.AWS_APPS_ENV == 'prod') {
    thisHost = host.prod;
  }
  var result = 'https://' + thisHost + '/';
  console.log('Computed root URL = ' + result);
  return result;
}

exports = module.exports = {
  // List of user roles, this property is used by Angular, the details of which are why we keep it here, separate
  // from the authConfig
  // TODO: modify Angular so that it uses 'userRoles' as part of the authConfig, by creating an Angular authConfig service
  appName: appName,
  host: host,
  userRoles: ['guest', 'user', 'admin'],
  getRootUrl: getRootUrl,
  authConfig: {
    allowedAuth: allowedAuth,
    appName: appName,
    isAuthTypeAllowedOnHost: isAuthTypeAllowedOnHost
  }
};
