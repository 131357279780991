'use strict';
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routes from './find-fund.routes';

export class FindFundComponent {

  donationGroupCode;

  donationSchoolCode;

  $sessionStorage;

  constructor($stateParams, givingService, $sessionStorage) {
    'ngInject';
    this.$stateParams = $stateParams;
    this.givingService = givingService;
    this.$sessionStorage = $sessionStorage;
  }

  $onInit() {
    this.donationGroupCode = this.$stateParams.area;
    this.donationSchoolCode = this.$stateParams.program;
    this.amount = this.$stateParams.amount;
    this.appeal = this.$stateParams.appeal;
  }

  onSchoolSelect(funds) {
    this.funds = funds;
  }

  // GIVING-279
  // show the spotlight image that goes
  // with the last thing added to the cart, or the default image if there's nothing.
  // I don't know if it's possible to get to this page without anything in the
  // cart, but let's be safe and handle that case.
  getImage() {
    return this.$sessionStorage.backgroundImage;
  }

}

export default angular.module('givingApp.findFund', [uiRouter]).config(routes).component('findFund', {
  template: require('./find-fund.html'),
  bindings: {
    fund: '<',
    amount: '<',
    appeal: '<'
  },
  controller: FindFundComponent
}).name;
