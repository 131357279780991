/**
 * Created by mkreddy on 7/3/17.
 */

'use strict';

export default class CountryCode {

  /** @type {number} */
  hibernate_id = -1;

  /** @type {string} */
  country_code = '';

  /** @type {string} */
  country_name = '';

  /** @type {?string} */
  active = null;

  /** @type {?number} */
  list_order = null;

  /** @type {?string} */
  country_code_3char = null;

  /** @type {?string} country_name */
  name = null;

  /** @type {?string} country_code */
  code = null;

}
