'use strict';
import angular from 'angular';

export class shortIndicatorComponent {
    /*@ngInject*/
    constructor() {}
}

export default angular.module('givingApp.short-indicator', []).component('shortIndicator', {
    template: require('./short-indicator.html'),
    bindings: {
        step: '@'
    },
    controller: shortIndicatorComponent
}).name;
