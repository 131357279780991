'use strict';
import angular from 'angular';

export class dropdownComponent {

    constructor() {
        'ngInject';
    }

    $onInit() {
    }

    getIdFromName(item) {
      if (!item) {
        return null;
      } else if (item.code) {
        // console.log(item.code);
        return "code";
      } else if (item.name) {
        // console.log (item.name.split(' ').join('_'));
        return item.name.split(' ').join('_');
      } else {
        return "name";
      }
    }

}

export default angular.module('givingApp.dropdown', []).component('dropdown', {
    template: require('./dropdown.html'),
    bindings: {
        items: '<',
        label: '=',
        trigger: '&',
        dropdownId: '@'
    },
    controller: dropdownComponent
}).name;
