import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routing from './main.routes';
import 'ngstorage';
import duScroll from 'angular-scroll';

export class MainController {

  /** @type {!DataLoader} */
  dataLoader;

  privateMode = false;

  /*@ngInject*/
  constructor(
    $http,
    $scope,
    $window,
    dataLoader,
    Util,
    $state,
    $document,
    $location,
    $stateParams,
    $sessionStorage,
  ) {
    this.$http = $http;
    this.$window = $window;
    this.$state = $state;
    this.dataLoader = dataLoader;
    this.generalLabel = 'Select general area';
    this.programLabel = 'Now select a program';
    this.util = Util;
    this.$document = $document;
    this.$location = $location;
    this.$stateParams = $stateParams;
    this.$sessionStorage = $sessionStorage;
  }

  $onInit() {
    if (
      this.$stateParams.program === null
      && this.$stateParams.fund === null
      && this.$stateParams.amount === null
      && this.$stateParams.fastStart === null
      && this.$stateParams.hash === null
      && this.$stateParams.appeal !== null
      && this.$stateParams.type === null
      && this.$stateParams.area === null
    ) {
      // WR-419 if only thing set is the appeal, then
      // store it and don't redirect to fund page
      this.$sessionStorage.appeal = this.$stateParams.appeal;
    } else {
      var baseUrl = window.location.href;
      if (baseUrl.indexOf('?') !== -1) {
        var parameter = "/fund";
        var position = baseUrl.indexOf('?');
        var _url = parameter + baseUrl.slice(position);
        this.$location.url(_url);
      }
    }
    //TODO: adjust to make more dynamic
    this.heroImage = this.util.getImageUrlMobileOrDesktop('1001_tiles_mobile', '1001_tiles_desktop');

    var storageTestKey = 'test';
    var storage = window.sessionStorage;
    try {
      storage.setItem(storageTestKey, 'test');
      storage.removeItem(storageTestKey);
    } catch (e) {
      if (e.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
        this.privateMode = true;
      } else {
        this.privateMode = false;
      }
    }
  }

  onSchoolSelect(funds) {
    this.funds = funds;
  }

  isReady() {

    let ready = this.dataLoader.isReady();
    return ready;
  }

  getHeroText()
  {
    const heroTextParams = this.dataLoader.getControlParams('HERO_TEXT');
    return (heroTextParams.length > 0 && heroTextParams[0].value) || 'Explore how your donations can make a difference to life at Penn';
  }

  getHeroURL()
  {
    const heroURLParams = this.dataLoader.getControlParams('HERO_URL');
    return (heroURLParams.length > 0 && heroURLParams[0].value) || 'https://www.giving.upenn.edu';
  }

  browseFundsClicked() {
    this.bottom = this.bottom ? this.bottom : angular.element(document.getElementById('bottom'));
    this.$document.scrollToElement(this.bottom, 0, 2000);
  }

  scrollToSearchResults() {
    this.searchResultsAnchor = this.searchResultsAnchor
      ? this.searchResultsAnchor
      : angular.element(document.getElementById('searchResults'));
    this.$document.scrollToElement(this.searchResultsAnchor, 0, 1000);
  }

}


export default angular.module('givingApp.main', [uiRouter, duScroll])
  .config(routing)
  .component('main', {
    template: require('./main.html'),
    controller: MainController
  })
  .name;
