'use strict';

import angular from 'angular';
import moment from 'moment';

//NOTE: Docs for moment.js => https://momentjs.com/


export class frequencyCalculatorService {

    constructor() {
        'ngInject';
    }

    get = (frequency, endDate) => {

        if (!endDate) { //GIVING-305
          return 0;
        }

        let period;

        if (frequency === 0) {
            period = "weeks";
        }

        if (frequency === -1 || frequency === 1) {
            period = "months";
        }

        if (frequency === 2) {
            period = "years";
        }

        let calculation = moment(endDate).diff(moment(new Date().setHours(0, 0, 0, 0)), period);

        if (frequency === -1) {
          calculation = Math.floor(calculation / 3);
        }

        // GIVING-305
        if (calculation == 0) {
          calculation = 1;
        }
        return calculation;
    }
}

export default angular.module('givingApp.frequencyCalculator', [])
    .service('frequencyCalculator', frequencyCalculatorService)
    .name;
