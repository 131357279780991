/**
 * Created by samd on 7/18/17.
 */
import DonationSpotlightFund from "./DonationSpotlightFund";

/**
 * Combines fund spotlight, school spotlight, and default spotlight
 * into one spotlight that applies to fund.
 */
export default class DonationSpotlightFundAdapter {

  /** @type {!DonationFund} */
  fund;

  /** @type {!DonationSpotlightFund[]} */
  donationSpotlightFunds = [];

  /**
   * @param {!DonationFund} fund
   * @param {!DonationSpotlightFund[]} donationSpotlightFunds
   */
  constructor(fund, donationSpotlightFunds) {
    this.fund = fund;
    this.donationSpotlightFunds = donationSpotlightFunds;

    this.getSpotlightFund();

    if (!this.spotlightFundFund) {
      this.spotlightFundFund = this.spotlightFundSchool;
    }

    if (!this.spotlightFundFund) {
      this.spotlightFundFund = this.spotlightFundDefault;
    }
    // Let's not mess with the original data
    this.spotlightFundFund = Object.assign(new DonationSpotlightFund(), this.spotlightFundFund);

    // If there's no image, fall back to school if it's there
    if (!this.spotlightFundFund.image_file && this.spotlightFundSchool) {
      this.spotlightFundFund.image_file = this.spotlightFundSchool.image_file;
      this.spotlightFundFund.mobile_image_file = this.spotlightFundSchool.mobile_image_file;
    }

    // Still no image - fall back to default
    if (!this.spotlightFundFund.image_file) {
      this.spotlightFundFund.image_file = this.spotlightFundDefault.image_file;
      this.spotlightFundFund.mobile_image_file = this.spotlightFundDefault.mobile_image_file;
    }

    // Missing mobile_image_file
    if (!this.spotlightFundFund.mobile_image_file) {
      this.spotlightFundFund.mobile_image_file = this.spotlightFundFund.image_file;
    }

    // Fill these in case they aren't already
    this.spotlightFundFund.school_code = this.fund.school_code;
    this.spotlightFundFund.fund_code = this.fund.fund_code;
  }

  /**
   * @return {!DonationSpotlightFund}
   */
  adapt() {
    return this.spotlightFundFund;
  }

  getSpotlightFund() {
    for (let spotlightFund of this.donationSpotlightFunds) {
      if (this.spotlightFundFund && this.spotlightFundSchool && this.spotlightFundDefault) {
        break;
      }
      if (this.fund.fund_code === spotlightFund.fund_code
        && this.fund.school_code === spotlightFund.school_code) {
        this.spotlightFundFund = spotlightFund;
      }

      if (!spotlightFund.fund_code && this.fund.school_code === spotlightFund.school_code) {
        this.spotlightFundSchool = spotlightFund;
      }
      if (!spotlightFund.school_code) {
        this.spotlightFundDefault = spotlightFund;
      }
    }
  }


}


