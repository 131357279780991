import angular from 'angular';
import sharedConfig from '../../../server/config/environment/shared'


/**
 * The Util service is for thin, globally reusable, utility functions
 * @param {cartMemoryService} cartMemory
 */
export function UtilService($window, $location, cartMemory) {
  'ngInject';
  this.imageBaseUrl = null;

  var Util = {

    /**
     * Return a callback or noop function
     *
     * @param  {Function|*} cb - a 'potential' function
     * @return {Function}
     */
    safeCb(cb) {
      return angular.isFunction(cb) ? cb : angular.noop;
    },

    /**
     * Parse a given url with the use of an anchor element
     *
     * @param  {String} url - the url to parse
     * @return {Object}     - the parsed url, anchor element
     */
    urlParse(url) {
      var a = document.createElement('a');
      a.href = url;

      // Special treatment for IE, see http://stackoverflow.com/a/13405933 for details
      if (a.host === '') {
        a.href = a.href;
      }

      return a;
    },

    /**
     * Test whether or not a given url is same origin
     *
     * @param  {String}           url       - url to test
     * @param  {String|String[]}  [origins] - additional origins to test against
     * @return {Boolean}                    - true if url is same origin
     */
    isSameOrigin(url, origins) {
      url = Util.urlParse(url);
      origins = origins && [].concat(origins) || [];
      origins = origins.map(Util.urlParse);
      origins.push($window.location);
      origins = origins.filter(function (o) {
        let hostnameCheck = url.hostname === o.hostname;
        let protocolCheck = url.protocol === o.protocol;
        // 2nd part of the special treatment for IE fix (see above):
        // This part is when using well-known ports 80 or 443 with IE,
        // when $window.location.port==='' instead of the real port number.
        // Probably the same cause as this IE bug: https://goo.gl/J9hRta
        let portCheck = url.port === o.port || o.port === '' && (url.port === '80' || url.port ===
          '443');
        return hostnameCheck && protocolCheck && portCheck;
      });
      return origins.length >= 1;
    },

    getImageUrlMobileOrDesktop(imageMobile, imageDesktop) {
      this.imageBaseUrl = this.imageBaseUrl ? this.imageBaseUrl : this.getImageBaseUrl();
      let imageUrl = null;
      if ($window.innerWidth <= 800) {
        imageUrl = this.imageBaseUrl + imageMobile;
      } else {
        imageUrl = this.imageBaseUrl + imageDesktop;
      }
      return imageUrl;
    },

    /**
     * Get the base image url. Will vary depending on environment.
     *
     * Will have trailing '/'.
     *
     * @return {!string}
     */
    getImageBaseUrl() {

      let host = $location.host();
      if ((host.indexOf(sharedConfig.host.prod) > -1) || (host.indexOf(sharedConfig.host.prod_lcf) > -1)) {
        return 'https://s3.amazonaws.com/givingprodimage.cloudfront.apps.upennaws.com/'
      } else if ((host.indexOf(sharedConfig.host.test) > -1) || (host.indexOf(sharedConfig.host.test_lcf) > -1)) {
        return 'https://s3.amazonaws.com/givingtestimage.cloudfront.apps.upennaws.com/'
      }
      return 'https://s3.amazonaws.com/givingdevimage.cloudfront.apps.upennaws.com/'
    },

    /**
     * Is the cart compatible with Stripe?
     */
    isStripeCart(cartContents) {
      let useStripe = false;
      let cartStatus = cartMemory.cartContentsStatus(cartContents);
      if (cartStatus.oneTime
        && !cartStatus.pledgeOneOff
        && !cartStatus.pledgeRecurring) {
        useStripe = true;
      } else {
        useStripe = false;
      }
      return useStripe;
    },

    /**
     * Should we check out with Stripe for this donation?
     */
    useStripeCheckout(cartContents, userInformation) {
      let useStripe = false;
      if (
        this.isStripeCart(cartContents)
        && _.get(userInformation, 'address.countryCode', '') !== 'GB') {
        useStripe = true;
      } else {
        useStripe = false;
      }
      return useStripe;
    },

    /**
     * Should we check out with Chariot for this donation?
     */
    useChariotCheckout(cartContents) {
      let cartStatus = cartMemory.cartContentsStatus(cartContents);
      return cartStatus.donorAdvisedFund;
    },

    /**
     * @param {string} currencySymbolTemplate eg '{U+062F}{U+002E}{U+0625}' because Atlas is ASCII
     * @return {string} instantiated template
     */
    toCurrencySymbol(currencySymbolTemplate) {
      let currencySymbol = '';
      for (let i = 0; i < currencySymbolTemplate.length;) {
        if (currencySymbolTemplate.substring(i, i + 3) === '{U+') {
          let sub = currencySymbolTemplate.substring(i + 3, i + 3 + 4);
          let part = String.fromCharCode(parseInt(sub, 16));
          currencySymbol += part;
          i += 8;
        } else {
          currencySymbol += currencySymbolTemplate.charAt(i);
          i++;
        }
      }
      return currencySymbol;
    }
  };

  return Util;
}
