'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('fund', {
      url: '/fund?program&fund&amount&fastStart&hash&appeal&type',
      params: {
          program: null,
          fund: null,
          amount: null,
          fastStart: null,
          hash: null,
          appeal: null,
          type: null,
      },
      template: '<fund></fund>',
      resolve: {
        'DataLoader': function (dataLoader) {
          // helps with testing so you can reload the page and should
          // be harmless as far as performance since it's coming out of local storage at this point
          return dataLoader.loadCaches();
        }
      }
    });
}
