'use strict';
import angular from 'angular';

export class fundTileLoaderComponent {

  dataLoader;
  highlights;

  /*@ngInject*/
  constructor(dataLoader) {
    this.dataLoader = dataLoader;
  }

  findSchoolBySchoolCode(schoolCode) {
    for (let donationSchool of this.dataLoader.donationSchools) {
      if (donationSchool.school_code === schoolCode) {
        return donationSchool;
      }
    }
  }

  $onInit() {
    var self = this;

    // why isn't this done in dataLoader when donationGiftingTiles are retrieved?
    let donationGiftingTiles = [];

    this.dataLoader.donationGiftingTiles.forEach(function(donationGiftingTile){
      if (donationGiftingTile.school_code) {
        donationGiftingTile.group_code = self.findSchoolBySchoolCode(donationGiftingTile.school_code).group_code;
      }
      donationGiftingTiles.push(donationGiftingTile);
    });
    this.highlights = donationGiftingTiles.filter((fund) => {
      return 'TILE' == fund.image_type;
    });

  }
}


export default angular.module('givingApp.fund-tile-loader', [])
  .component('fundTileLoader', {
    template: require('./fund-tile-loader.html'),
    controller: fundTileLoaderComponent
  })
  .name;
