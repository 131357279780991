'use strict';
const angular = require('angular');

export class CurrencyDropdownController {

  /**
   * From above
   * @type {ControlParam[]}
   */
  currencyControlParams;

  /**
   * From above - signal a change in currency
   * @type {function}
   */
  changeCurrency;

  currencies = [];

  /**
   * @type {string}
   */
  currenciesLabel = '';

  /*@ngInject*/
  constructor() {
  }

  $onInit() {
    this.initCurrencies();
    this.currencySelected(this.currencies[0]);
  }

  /**
   * Currency selected in the UI
   * @type {Object}
   */
  selectedCurrency = null;

  /**
   * The last currency that was selected.
   * @type {Object}
   */
  previousSelectedCurrency = null;

  currencySelected(item) {
    this.previousSelectedCurrency = this.selectedCurrency;
    this.selectedCurrency = item;
    this.currenciesLabel = item.name;

    /** @type {ControlParam} */
    let controlParam = this.currencyControlParams.find(controlParam => {
      return controlParam.value === item.value;
    });
    let currencySymbol = '';
    let currencyCode = controlParam.value;
    if (controlParam.comments) {
      currencySymbol = controlParam.comments;
    }
    this.changeCurrency(
      {
        currencySymbol: currencySymbol,
        currencyCode: currencyCode,
        previousCurrencyCode: _.get(this.previousSelectedCurrency, 'value', null)
      }
    );
  }

  initCurrencies() {
    this.currencies = [];
    this.currencyControlParams.forEach(item => {
      this.currencies.push({name: item.description, value: item.value});
    });
  }

}

export default angular.module('wdayreallocationApp.currencyDropdown', [])
  .component('currencyDropdown', {
    template: require('./currencyDropdown.html'),
    bindings: {
      currencyControlParams: '<',
      changeCurrency: '&',
    },
    controller: CurrencyDropdownController,
  })
  .name;
