/**
 * Created by samd on 6/29/17.
 */
'use strict';
/**
 * From the /control_param service.
 *
 * Setting all fields to aid in testing - make sure
 * json has correct fields when copied over.
 */
export default class ControlParam {

  /**
   * @const
   * @type {string}
   */
  static CURRENCY_INDICATOR = 'CURRENCY_INDICATOR';

  /** @type {number} */
  hibernate_id = -1;

  /** @type {string} */
  parameter = '';

  /** @type {string} */
  value = '';

  /** @type {?string} */
  parent_value = null;

  /** @type {?string} */
  description = null;

  /** @type {?number} */
  list_order = null;

  /** @type {?string} */
  comments = null;

  /** @type {?number} */
  group_value = null;

}
