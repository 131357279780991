'use strict';
import * as _ from "lodash";

const angular = require('angular');

/**
 * For services shared throughout the app.
 */
class GivingService {

  $q;
  $http;
  $document;
  $interval;
  $window;

  /*@ngInject*/
  constructor(
    $q,
    $http,
    $document,
    $interval,
    $window,
    $rootScope,
  ) {
    this.$q = $q;
    this.$http = $http;
    this.$document = $document;
    this.$interval = $interval;
    this.$window = $window;
    this.$rootScope = $rootScope;
  }

  addRecaptchaScript() {
    this.$window.recaptchaOnloadCallback = this.recaptchaOnloadCallback;
    let tag = this.$window.document.createElement('script');
    tag.src = "https://www.google.com/recaptcha/api.js?onload=recaptchaOnloadCallback&render=explicit";
    this.$document.find('body')[0].appendChild(tag);
  }

  initRecaptchaCalled = false;

  initRecaptcha() {
    if (this.initRecaptchaCalled) {

    } else {
      this.initRecaptchaCalled = true;
      this.addRecaptchaScript();
    }
  }

  grecaptcha = null;

  recaptchaOnloadCallback = () => {
    this.grecaptcha = this.$window.grecaptcha;
  };

  /**
   * @return {Object} grecaptcha, in a promise
   */
  getGrecaptcha() {
    this.initRecaptcha();
    let deferredWait = this.$q.defer();
    if (this.grecaptcha) {
      deferredWait.resolve();
    } else {
      let wait = this.$interval(() => {
        if (this.grecaptcha) {
          this.$interval.cancel(wait);
          deferredWait.resolve();
        }
      }, 50);
    }
    return deferredWait.promise.then(() => {
      return this.grecaptcha;
    });
  }

  /**
   * @param {number} amount
   * @param {string} currency
   * @param {string} recaptchaToken
   * @return {GivingStripePaymentIntentResponse}
   */
  stripePaymentIntent(
    amount,
    currency,
    recaptchaToken,
  ) {
    /** @type {GivingStripePaymentIntentRequest} */
    let paymentIntentRequest = {
      amount: amount,
      currency: currency,
    };
    return this.$http(
      {
        url: '/api/stripe/payment_intent/',
        method: 'POST',
        data: paymentIntentRequest,
        headers: {
          'recaptcha-token': recaptchaToken,
        }
      }
    ).then(response => {
      return response.data;
    });
  }

  /**
   * @param {string} giftId
   * @param {StripePaymentMethod} stripePaymentMethod
   * @return {CompleteDonationResponse}
   */
  stripeCompletePaymentIntentDonation(
    giftId,
    stripePaymentMethod,
  ) {
    let completePaymentIntentDonation = {
      gift_id: giftId,
      payment_method_id: stripePaymentMethod.id,
      payment_method_card_brand: _.get(stripePaymentMethod, 'card.brand'),
    };
    return this.$http.post(
      '/api/stripe/complete_payment_intent_donation/',
      completePaymentIntentDonation,
    ).then(response => {
      return response.data;
    });
  }
}

export default angular.module('givingApp.givingService', [])
  .service('givingService', GivingService)
  .name;
