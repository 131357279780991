'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('search', {
      url: '/search/:term',
      template: '<search></search>',
      resolve: {
        'DataLoader': function (dataLoader) {
          // GIVING-492: need this for page reloads if session storage cache is not used for funds (so prevent a regression),
          // also allows direct url's as a side effect which did not used to work, but this is not a feature that
          // needs to be supported (as of this writing)
          return dataLoader.loadCaches();
        }
      }
    });
}
