'use strict';

import angular from 'angular';

export class currencySymbolService {

    /*@ngInject*/
    constructor(userData, $sessionStorage) {
        this.$sessionStorage = $sessionStorage;

        this.userDataService = userData;

        if (this.$sessionStorage.currencySymbolService) {
          this.currentSymbol = this.$sessionStorage.currencySymbolService.currentSymbol;
        } else {
          this.currentSymbol = {symbol: '$'};
          if (this.userDataService.get() !== false) {
            this.userInfo = this.userDataService.get();
            if (this.userInfo.GBP === true) {
              this.currentSymbol = {symbol: '£'};
            }
          }
          this.$sessionStorage.currencySymbolService = {currentSymbol: this.currentSymbol};
        }
    }

    get() {
        return this.currentSymbol;
    }

    set(symbol) {
        this.currentSymbol.symbol = symbol;
    }

    reset() {
        this.currentSymbol.symbol = "$";
    }

    // SD: to support GIVING-410
    setCurrencyCode(currencyCode) {
      this.currentSymbol.code = currencyCode;
    }

}

export default angular.module('givingApp.currencySymbol', [])
    .service('currencySymbol', currencySymbolService)
    .name;
