'use strict';
const angular = require('angular');
import {Decimal} from 'decimal.js';

export class StripeController {

  stripe;
  card;
  $scope;
  $timeout;

  /** @type {!DataLoader} */
  dataLoader;

  /**
   * From above
   */
  stripePublishableKey;

  /**
   * From above
   * @type {function}
   */
  submit;

  /**
   * From above.
   * For Stripe's payment request button
   * https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-complete-payment
   */
  submitPaymentRequest;

  /**
   * From above - should we disable our donate now button
   * @type {boolean}
   */
  disabled;

  /**
   * From above
   * @type {function}
   */
  getAmount;

  /**
   * From above
   * @type {function}
   */
  getStripePaymentIntent;

  /**
   * From above - the grecaptcha object
   * @type {object}
   */
  grecaptcha;

  /**
   * The currencies the app is using
   * @type {ControlParam[]}
   */
  currencyControlParams;

  /**
   * The chosen currency.
   * @type {Currency}
   */
  currency;

  disabledLocal = true;

  /**
   * If the submitted button is pressed, permanently disable the button
   * @type {boolean}
   */
  submitted = false;

  digitalWallet = false;

  /**
   * @type {SimpleModal}
   */
  simpleModal;

  /**
   * Set in sub-recaptcha so we can reset it here
   * @type {*}
   */
  recaptchaWidgetId;

  /** @ngInject */
  constructor(
    $scope,
    $timeout,
    simpleModal,
    dataLoader
  ) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.simpleModal = simpleModal;
    this.dataLoader = dataLoader;
  }

  /**
   * Put this into a function so can mock it in tests - don't know how to use Stripe in tests
   */
  getStripe() {
    return Stripe(this.stripePublishableKey);
  }

  /**
   * Put this into a function so can mock it in tests - seems easier than mocking getElementById
   */
  getDisplayError() {
    return document.getElementById('card-errors');
  }

  /**
   * Put this into a function so can mock it in tests - seems easier than mocking getElementById
   */
  getPaymentRequestButton() {
    return document.getElementById('payment-request-button');
  }

  handlePrButtonClickEvent = (event) => {
    if (this.submitted) {
      event.preventDefault();
      let options = {
        header: 'Payment Already Submitted',
        message: '<p>Payment already submitted</p>',
      };
      this.simpleModal.showAlert(options);
    } else if (this.disabled) {
      event.preventDefault();
      let options = {
        header: 'Fill In Missing Fields or Recaptcha',
        message: '<p>Please fill in missing fields or recaptcha</p>',
      };
      this.simpleModal.showAlert(options);
    } else {
      let options =
        this.paymentRequest.update({
          currency: this.currency.code.toLowerCase(),
          total: {
            label: 'Penn Giving',
            amount: this.checkCurrentCurrency(),
          },
        });
    }
  };

  checkCurrentCurrency() {
    if (this.dataLoader.getControlParam('ZERO_DECIMAL', this.currency.code)) {
      return new Decimal(this.getAmount()).toNumber();
    } else {
      return new Decimal(this.getAmount()).times(100).toNumber();
    }
  }

  $onInit() {
    this.stripe = this.getStripe();
    let elements = this.stripe.elements();
    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    let style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
      },
      invalid: {
        color: '#95001a',
        iconColor: '#95001a',
      }
    };

    // Create an instance of the card Element.
    this.card = elements.create('card', {style: style});

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount('#card-element');

    // Handle real-time validation errors from the card Element.
    this.card.addEventListener('change', (event) => {
      let displayError = this.getDisplayError();
      let error = '';
      if (_.get(event, 'brand', '').toLowerCase() === 'discover') {
        if (this.currency.code !== 'USD') {
          let usdControlParam = this.currencyControlParams.find((item) => {
            return (item.value === 'USD');
          });
          error = `Discover can only be used with ${usdControlParam.description}`;
        }
      }
      if (event.error) {
        displayError.textContent = event.error.message;
      } else if (error) {
        displayError.textContent = error;
      } else {
        displayError.textContent = '';
      }
      // the apply's seem to be necessary because this eventlistener is outside of
      // angular's control
      if (event.complete === true && error === '') {
        this.disabledLocal = false;
        this.$scope.$apply();
      } else {
        this.disabledLocal = true;
        this.$scope.$apply();
      }
    });
    this.paymentRequest = this.stripe.paymentRequest({
      country: 'US', // the country of Penn's account, not the end-user location
      currency: 'usd', // this is not available if fastStart=simpleForm  - update with amount later
      total: {
        label: 'Penn Giving',
        amount: 0, // will be filled in later
      },
      // from https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-create-payment-request-instance
      // Requesting the payer’s name, email, or phone is optional, but highly recommended, as it also results in
      // collecting their billing address for Apple Pay. The billing address can be used to perform address verification
      // and block fraudulent payments. For all other payment methods, the billing address is automatically collected
      // when available - see paymentRequest.update below
      requestPayerName: true,
    });

    let paymentRequest = this.paymentRequest;

    let prButton = elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest
    });

    prButton.on('click', this.handlePrButtonClickEvent);

    // Check the availability of the Payment Request API first.
    paymentRequest.canMakePayment().then((result) => {
      if (!result) {
        // Digital wallet is not available, don't display the button
        this.getPaymentRequestButton().style.display = 'none';
      } else {
        prButton.mount('#payment-request-button');
        paymentRequest.on('paymentmethod', this.handlePaymentmethodEvent);
        this.digitalWallet = true;

        // the apply's seem to be necessary because paymentRequest.canMakePayment is outside of
        // angular's control, else digital wallet html does not always apper
        // Using timeout instead of apply so we can keep the existing unit test, which calls digest.
        return this.$timeout();
      }
    });
  }

  handlePaymentmethodEvent = (ev) => {
    this.getStripePaymentIntent({amount: this.getAmount(), currency: this.currency.code.toLowerCase()})
      .then(result => {
        let clientSecret = result.client_secret;
        // Confirm the PaymentIntent without handling potential next actions (yet).
        this.stripe.confirmCardPayment(
          clientSecret,
          {payment_method: ev.paymentMethod.id},
          {handleActions: false}
        ).then((confirmResult) => {
          if (confirmResult.error) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            // SD: to test this case enter a Stripe failure card, eg
            // 4000000000000002	Charge is declined with a card_declined code.
            // see https://stripe.com/docs/testing#regulatory-cards
            ev.complete('fail');
            this.grecaptcha.reset(this.recaptchaWidgetId);
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11" instead
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (confirmResult.paymentIntent.status === "requires_source_action") {
              // Let Stripe.js handle the rest of the payment flow.
              this.stripe.confirmCardPayment(clientSecret).then(confirmResult2 => {
                if (confirmResult2.error) {
                  // The payment failed -- ask your customer for a new payment method.
                  let options = {
                    header: 'Card Authentication Failed',
                    message: '<p>Please try another payment method</p>',
                  };
                  this.simpleModal.showAlert(options);
                  this.submitted = false;
                  this.grecaptcha.reset(this.recaptchaWidgetId);
                } else {
                  // The payment has succeeded.
                  this.submitPaymentRequestHelper(ev.paymentMethod, confirmResult2.paymentIntent);
                }
              });
            } else {
              // The payment has succeeded.
              this.submitPaymentRequestHelper(ev.paymentMethod, confirmResult.paymentIntent);
            }
          }
        });
      })
      .catch(() => {
        // getting the payment intent failed - because of a stale recaptcha token for instance
        ev.complete('fail'); // not in example code, but shows an error as soon as exception is caught,
                             // otherwise it waits to time out
        // Let's not reset the recapthca widget since this (probably) means the payment intent was not retrieved
        // if we need more fine-grained control over different http error codes we can add them later but seems
        // we have the most common case covered: expired recaptcha
      });
  };

  submitPaymentRequestHelper(paymentMethod, paymentIntent) {
    this.submitted = true;
    this.submitPaymentRequest(
      {
        stripePaymentMethod: paymentMethod,
        stripePaymentIntent: paymentIntent,
      }
    );
  }

  donateNow() {

    if (this.shouldShowCitizenShip == true && (this.model.citizenshipType == null || this.model.citizenShipCountryCode == null)) {

      this.shouldShowCitizenShipError = true;
      return false;
    }

    if (this.submitted) {
      // GIVING-433 - this button should now be disabled but just to be sure
      // don't make dup donations let's check it here
    } else {
      this.submitted = true; // GIVING-433
      this.stripe.createToken(this.card).then((result) => {
        if (result.error) {
          // Inform the user if there was an error.
          let errorElement = this.getDisplayError();
          errorElement.textContent = result.error.message;
        } else {
          // Send the token to your server.
          this.submit({token: result.token});
        }
      });
    }
  }

  // From https://github.com/stripe/react-stripe-elements/issues/283
  // Assume that the user's input starts in an incomplete state and block form submission.
  // Use an element.on('change', (event) => …) event handler to update your form's state.
  // If there are errors in event.errors, display them and continue to block form submission.
  // When you receive a change event with event.complete == true, allow form submission to proceed.
  disableButton() {
    if (this.submitted) {
      // This case takes precedence over all cases
      return true;
    }
    let disabled;
    if (this.disabled) {
      disabled = true;
    } else {
      disabled = this.disabledLocal;
    }
    return disabled;
  }

  setRecaptchaWidgetId(id) {
    this.recaptchaWidgetId = id;
  }
}

export default angular.module('givingApp.stripe', [])
  .component('stripe', {
    template: require('./stripe.html'),
    bindings: {
      stripePublishableKey: '<',
      disabled: '<',
      submit: '&',
      currency: '<',
      currencyControlParams: '<',
      recaptchaSitekey: '<',
      grecaptcha: '<',
      recaptchaCallback: '&',
      recaptchaExpiredCallback: '&',
      submitPaymentRequest: '&',
      getAmount: '&',
      getStripePaymentIntent: '&',
      shouldShowCitizenShipError : '=',
      shouldShowCitizenShip: '=',
      model: '=',
    },
    controller: StripeController
  })
  .name;
