/**
 * Created by samd on 6/8/17.
 */

'use strict';

export default class DonationFund {
  /** @type {number} */
  hibernate_id = -1;

  /** @type {?string} */
  fund_code;

  /** @type {?string} */
  school_code;

  /** @type {?string} */
  fund_name;

  /** @type {number} */
  list_order = -1;

  active_ind;

  /** @type {string} */
  spotlight_ind = '';

  is_membership;
  discount_ind;
  goods_or_services_given;
  show_anonymous_gift;
  match_gfts_simple;
  social_fundraising_ind;
  special_processing;


  /** @type {?string} */
  long_description;

  // get _id() {
  //   return this.fund_code
  // }
  //
  // /**
  //  * @return {?string}
  //  */
  // get name() {
  //   return this.fund_name;
  // }
  //
  // get program_id() {
  //   return this.school_code;
  // }
  //
  // get description() {
  //   return this.long_description;
  // }


}
