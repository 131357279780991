'use strict';
const angular = require('angular');


export class AreaProgramComponent {

  DEFAULT_AREA_LABEL = 'Select general area';
  DEFAULT_PROGRAM_LABEL = 'Now select a program';

  /** @type {?string} */
  areaId = null;

  /** @type {string} */
  areaLabel = this.DEFAULT_AREA_LABEL;

  /** @type {!DonationGroup[]) */
  areaListing = [];

  /** @type {!string} */
  programLabel = this.DEFAULT_PROGRAM_LABEL;

  /** @type {!DonationSchool[]} */
  programListing = [];

  /** @type {!DataLoader} */
  dataLoader;


  /**
   * Function of groupCode - see $onInit()
   * @type {DonationGroup}
   */
  group;

  /**
   * Function of schoolCode - see $onInit()
   * @type {DonationSchool}
   */
  school;

  /**
   * Component input.
   * @type {string}
   */
  groupCode;

  /**
   * Component input.
   * @type {string}
   */
  schoolCode;

  /**
   * Component output.
   */
  onSchoolSelect;


  /** @ngInject */
  constructor(dataLoader) {
    this.dataLoader = dataLoader;
  }

  /**
   * @param groupCode {!string}
   * @return {!DonationSchool[]}
   */
  findSchoolsByGroupCode(groupCode) {
    let donationSchools = [];
    for (let donationSchool of this.dataLoader.donationSchools) {
      if (donationSchool.group_code === groupCode) {
        donationSchools.push(donationSchool);
      }
    }
    return donationSchools;
  }




  /**
   * @param groupOrGroupCode {@type !DonationGroup}
   */
  areaSelected(donationGroup) {
    this.programListing = [];
    this.dataLoader.selectedFunds = [];

    this.onSchoolSelect({funds: []});

    this.programLabel = this.DEFAULT_PROGRAM_LABEL;


    //let n = (item - 1);
    this.areaId = donationGroup.group_code;
    this.areaLabel = donationGroup.name;

    this.programListing = this.findSchoolsByGroupCode(donationGroup.group_code);
    this.areaLabel = donationGroup.name;
    if (this.school) {
      this.programSelected(this.school);
    }
  }

  /**
   *
   * @param schoolCode {!string}
   * @param onlySpotlightFunds {boolean} only return spotlightFunds?
   * @return {!DonationFund[]}
   */
  findFundsBySchoolCode(schoolCode, onlySpotlightFunds) {
    let results = [];
    for (let fund of this.dataLoader.funds) {
      if (fund.school_code === schoolCode) {
        if (onlySpotlightFunds) {
          if (fund.spotlight_ind === 'T') {
            results.push(fund);
          }
        } else {
          results.push(fund);
        }
      }
    }
    return results;
  }


  /**
   * @param school {@type !DonationSchool}
   */
  programSelected(school) {
    let selectedFunds =
      this.findFundsBySchoolCode(school.school_code, true);

    this.onSchoolSelect({funds: selectedFunds});

    this.programLabel = school.name;

    // set this so we don't reload schools again on subsequent calls to areaSelected
    this.school = null;
  }

  /**
   * @param groupCode {!string}
   * @return {!DonationGroup}
   */
  findGroupByGroupCode(groupCode) {
    for (let donationGroup of this.dataLoader.donationGroups) {
      if (donationGroup.group_code === groupCode) {
        return donationGroup;
      }
    }
  }


  $onInit() {
    this.areaListing = this.dataLoader.donationGroups;
    if (this.groupCode) {
      this.group = this.findGroupByGroupCode(this.groupCode);
      if (this.schoolCode) {
        this.school = this.dataLoader.findSchoolBySchoolCode(this.schoolCode);
      }
      this.areaSelected(this.group);
    }
  }
}

export default angular.module('givingApp.areaProgram', [])
  .component('areaProgram', {
    template: require('./areaProgram.html'),
    bindings: {
      groupCode: '@',
      schoolCode: '@',
      onSchoolSelect: '&',
    },
    controller: AreaProgramComponent
  })
  .name;
