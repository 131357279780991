/**
 * Created by mkreddy on 7/17/17.
 */

'use strict';

/**
 * From the /donation_image_school service.
 *
 * Setting all fields to aid in testing - make sure
 * json has correct fields when copied over.
 */
export default class DonationImageSchool {
  /** @type {number} */
  hibernate_id = -1;

  /** @type {?string} */
  school_code = null;

  /** @type {?string} */
  image_message = null;

  /** @type {string} */
  active = '';

  /** @type {?string} */
  image_title = null;

  /** @type {?string} */
  image_file = null;

  /** @type {?string} */
  fund_code = null;

  /** @type {?string} */
  mobile_image_file = null;
}
