'use strict';

export default function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('main', {
    url: '/?program&fund&amount&fastStart&hash&appeal&type&area',
    template: '<main></main>',
    params: {
      program: null,
      fund: null,
      amount: null,
      fastStart: null,
      hash: null,
      appeal: null,
      type: null,
      area: null,
    },
    onEnter: ($location, dataLoader) => {
      // GIVING-492: moved this code over from app.js so that
      // funds are not loaded unnecessarily on the thank-you page
      // for cybersource gifts

      // SD: this code always evaluates to true, so commenting it out
      // var local = $location.host().indexOf('localhost') > -1;
      // var https = $location.protocol().indexOf('https') > -1;
      // just to be safe, restrict dataLoader startup to local/http, aws/https
      // if ( (local && !https) || (!local || https) ) {
      dataLoader.loadCaches();
      //}
    }
  });
}
