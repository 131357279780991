'use strict';
import angular from 'angular';

export class stepIndicatorComponent {

    constructor() {
        'ngInject';
    }
}

export default angular.module('givingApp.step-indicator', []).component('stepIndicator', {
    template: require('./step-indicator.html'),
    bindings: {
        step: '@'
    },
    controller: stepIndicatorComponent
}).name;
