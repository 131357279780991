/**
 * Created by mkreddy on 7/11/17.
 */

'use strict';

/**
 * From the /donation_group  service.
 *
 * Setting all fields to aid in testing - make sure
 * json has correct fields when copied over.
 */
export default class DonationGroup {

  /** @type {number} */
  hibernate_id = -1;

  /** @type {string} */
  group_code = '';

  /** @type {?string} */
  group_name = null;

  /** @type {?number} */
  list_order = null;

  /** @type {string} */
  active_ind = '';

  get name() {
    return this.group_name;
  }

  /** Unique id - not sure how stable hibernate_id is. */
  get code() {
    return this.group_code;
  }



}
