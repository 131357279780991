/**
 * Created by samd on 8/1/17.
 */

export default class Detail {

  /** @type {number} */
  detail_id = -1;

  /**
   * This points at a Header.gift_id.
   * @type {number}
   */
  gift_id = -1;

  /** @type {?string} */
  fund_code;

  /** @type {?string} */
  group_code;

  /** @type {?string} */
  school_code;

  /** @type {?string} */
  gift_method_type_joint;

  /** @type {?string} */
  anonymous_gift;

  /** @type {?string} */
  in_honor_or_memory_of;

  /** @type {?string} */
  in_honor_or_memory_of_name;

  /** @type {?string} */
  gift_special_reason;

  /** @type {?string} */
  gift_special_reason_desc;

  /**
   * Money, 99.99
   * @type {?number}
   */
  amount;

  /** @type {?number} */
  share_number;

  /** @type {?string} */
  security_name;

  /** @type {?string} */
  ticker_symbol;

  /** @type {?string} */
  gift_method_type;

  /** @type {?string} */
  pledge_start_date;

  /**
   * Date YYYY-MM-DD
   * @type {?string}
   */
  recurr_start_date;

  /**
   * 'A', 'M', 'Q', 'Y'
   * @type {?string} */
  recurr_frequency;

  /** @type {?string}
   *  */
  status;


  /** @type {?Date} */
  date_sent_to_gifts;

  /** @type {?string} */
  emplid;

  /** @type {?string} */
  appeal_code;

  /**
   * Money: 99.99
   * @type {?number}
   */
  pledge_total_year;

  /**
   * 'A', 'M', 'Q', 'Y'
   * @tyep {?string}
   */
  pledge_frequency;

  /** @type {?string} */
  //rec_lock;

  /** @type {?string} */
  //recorder_id;

  /**
   * @type {?Date}
   */
  //date_recorded;

  /** @type {?string} */
  //soft_credit_id;

  /** @type {?string} */
  //sundry_id_sw;

  /** @type {?string} */
  in_honor_or_memory_of_email;

  /** @type {?string} */
  in_honor_or_memory_of_address;

  /** @type {?string} */
  in_honor_or_memory_of_city;

  /** @type {?string} */
  in_honor_or_memory_of_state;

  /** @type {?string} */
  in_honor_or_memory_of_zip;

  /** @type {?string} */
  in_honor_or_memory_of_country;

  /** @type {?string} */
  in_honor_or_memory_of_comments;

  /** @type {?string} */
  planned_giving_flag;

  /** @type {?string} */
  planned_giving_more_info;

  /** @typ {?string} */
  planned_giving_penn_in_will;

  /** @type {?string} */
  in_honor_or_memory_ack_name;

  /** @type {?string} */
  gift_method_type_joint_name;

  // not our business
  //etl_date_time = models.DateTimeField(blank = True, null = True)
}
