/**
 * Created by mkreddy on 7/3/17.
 */

export default class StateCode {

  /** @type {?number} */
  hibernate_id = null;

  /** @type {?string} */
  country_code = null;

  /** @type {?string} */
  state_code = null;
  /** @type {?string} */
  state_name = null;

  /** @type {?number} */
  active = null;

  /** @type {?string} */
  display_state_name = null;
}
