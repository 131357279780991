'use strict';
import angular from 'angular';
import DonationSpotlightFund from "../../app/givingService/DonationSpotlightFund";
import DonationSpotlightFundAdapter from "../../app/givingService/DonationSpotlightFundAdapter";

export class fundTileComponent {

  /** @type {!DonationFund} */
  fund;

  /** @type {!DataLoader} */
  dataLoader;

  util;

  constructor($uibModal, dataLoader, Util) {
    'ngInject';
    this.$uibModal = $uibModal;
    this.dataLoader = dataLoader;
    this.util = Util;
  }

  $onInit() {

    let spotlightFund =
      new DonationSpotlightFundAdapter(this.fund, this.dataLoader.donationSpotlightFunds).adapt();

    this.openModal = function () {
      this.$uibModal.open({
        animation: true,
        size: 'xlg',
        component: 'modal',
        windowTopClass: 'tbmodal',
        backdrop: false,
        resolve: {
          spotlightFund: spotlightFund
        }
      });
    }
  }

}

export default angular.module('givingApp.fundTile', []).component('fundTile', {
  template: require('./fund-tile.html'),
  bindings: {
    fund: '<',
    amount: '<',
    appeal: '<'
  },
  controller: fundTileComponent
}).name;
