/**
 * Created by samd on 7/13/17.
 */

'use strict';

/**
 * From the /donation_spotlight_fund service.
 *
 * Setting all fields to aid in testing - make sure
 * json has correct fields when copied over.
 */
export default class DonationSpotlightFund {
  /** @type {number} */
  hibernate_id = -1;

  /** @type {?string} */
  school_code = null;

  /** @type {?string} */
  spotlight_message = null;

  /** @type {string} */
  active = '';

  /** @type {?string} */
  spotlight_title = null;

  /** @type {?string} */
  image_file = null;

  /** @type {?string} */
  link_url = null;

  /** @type {?string} */
  link_text = null;

  /** @type {?string} */
  welcome_message = null;

  /** @type {?string} */
  link_url2 = null;

  /** @type {?string} */
  link_text2 = null;

  /** @type {?string} */
  link_url3 = null;

  /** @type {?string} */
  link_text3 = null;

  /** @type {?string} */
  link_url4 = null;

  /** @type {?string} */
  link_text4 = null;

  /** @type {?string} */
  link_url5 = null;

  /** @type {?string} */
  link_text5 = null;

  /** @type {?string} */
  fund_code = null;

  /** @type {?string} */
  mobile_image_file = null;




}
