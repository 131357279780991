import angular from 'angular';
import * as _ from "lodash";

export class cartMemoryService {

  /** @type {!frequencyCalculatorService} */
  frequencyCalculator;

  foundItem;

  constructor($sessionStorage, frequencyCalculator) {
    'ngInject';
    this.sessionStorage = $sessionStorage;
    this.frequencyCalculator = frequencyCalculator;

    if (this.sessionStorage.cart) {
      this.cartContents = $sessionStorage.cart;
    } else {
      this.cartContents = [];
    }
    if (this.sessionStorage.foundItem) {
      this.foundItem = this.sessionStorage.foundItem;
    }
  }


  add = (data) => {
    //Calculate number of payments for CyberSource
    if (data.frequency <= 2) {
      data.numberOfPayments = this.frequencyCalculator.get(data.frequency, data.endDate);
      data.startDate = new Date();
    }
    if (data.type == 4 && data.frequency <= 2) {
      // frequency should always be <=2, but let's check anyway
      data.numberOfPayments = this.getNumPaymentsPerYearForPledge(data);
    }
    this.cartContents.push(data);
    this.sessionStorage.cart = this.cartContents;
  };

  remove = (id) => {
    for (let i = 0; i < this.cartContents.length; i++) {
      if (this.cartContents[i].id === id) {
        this.cartContents.splice(i, 1);
        this.sessionStorage.cart = this.cartContents;
        if (this.$scope) {
          this.$scope.$digest();
        }
      }
    }
    this.status();
  };

  empty = () => {
    this.cartContents = [];
    this.sessionStorage.cart = this.cartContents;
  };

  retrieve = () => {
    return this.cartContents;
  };

  getTotals = () => {
    return this.getTotalsForCartContents(this.cartContents);
  };

  getTotalsForCartContents = (cartContents) => {
    let cartTotals = {
      weekly: null,
      monthly: null,
      quarterly: null,
      yearly: null,
      oneTime: null,
      donorAdvisedFund: null,

      // total that cyber source will see (no pledge totals, or planned gifts)
      cyberSourceAmount: 0,

      // total include pledged amount
      totalAmount: 0
    };
    if (cartContents.length) {
      for (let i = 0; i < cartContents.length; i++) {
        cartTotals.cyberSourceAmount += this.getCyberSourceAmount(cartContents[i]);

        if (angular.isUndefined(cartContents[i].otherGift) || cartContents[i].otherGift === null) {
          cartTotals.totalAmount += this.parseIntWithCommas(cartContents[i].amount, 10);
        } else if (cartContents[i].type === 4 || cartContents[i].type === 6) {
          cartTotals.totalAmount += cartContents[i].otherGift.pledge.totalValue;
        } else if (cartContents[i].type === 7) { // Donor advised fund
          cartTotals.totalAmount += this.parseFloatWithCommas(cartContents[i].amount);
        }

        if (angular.isUndefined(cartContents[i].otherGift)
          || cartContents[i].otherGift === null
          || cartContents[i].type === 4) {
          if (cartContents[i].frequency === 0) {
            cartTotals.weekly = cartTotals.weekly + this.getCyberSourceAmount(cartContents[i]);
          }
          if (cartContents[i].frequency === 1) {
            cartTotals.monthly = cartTotals.monthly + this.getCyberSourceAmount(cartContents[i]);
          }
          if (cartContents[i].frequency === -1) {
            cartTotals.quarterly = cartTotals.quarterly + this.getCyberSourceAmount(cartContents[i]);
          }
          if (cartContents[i].frequency === 2) {
            cartTotals.yearly = cartTotals.yearly + this.getCyberSourceAmount(cartContents[i]);
          }
          if (cartContents[i].frequency === 3) {
            cartTotals.oneTime = cartTotals.oneTime + this.getCyberSourceAmount(cartContents[i]);
          }
        } else {
          if (cartContents[i].type === 6) {
            cartTotals.oneTime = cartTotals.oneTime + this.getCyberSourceAmount(cartContents[i]);
          }
          if (cartContents[i].type === 7) { // donor advised fund
            cartTotals.donorAdvisedFund = cartTotals.donorAdvisedFund
              + this.parseFloatWithCommas(cartContents[i].amount);
          }
        }
      }
    }
    return cartTotals;
  };

  parseIntWithCommas(intWithCommas) {
    if (typeof intWithCommas === 'number') {
      return intWithCommas;
    }
    let amountNoComma = intWithCommas.replace(/,/g, "");
    return parseInt(amountNoComma, 10);
  }

  /**
   * @param floatWithCommas {string|number}
   * @return {number}
   */
  parseFloatWithCommas(floatWithCommas) {
    if (typeof floatWithCommas === 'number') {
      return floatWithCommas;
    }
    let amountNoComma = floatWithCommas.replace(/,/g, "");
    return parseFloat(amountNoComma);
  }


  getPledgeYears(cartItem) {
    return cartItem.otherGift.timeSpan.valueNum;
  }

  getNumPaymentsPerYearForPledge(cartItem) {
    let numPayments = null;
    let years = this.getPledgeYears(cartItem);
    switch (cartItem.frequency) {
      case 0: // weeks
        numPayments = years * 52;
        break;
      case 1: // months
        numPayments = years * 12;
        break;
      case -1: // quarterly
        numPayments = years * 4;
        break;
      case 2: // annually
        numPayments = years * 1;
        break;
    }
    return numPayments;
  }


  status = () => {
    return this.cartContentsStatus(this.cartContents);
  };

  cartContentsStatus(cartContents) {
    let typesOfCartContents = {
      "recurring": false, // 0, 1, 2
      "oneTime": false, // 3
      "pledgeRecurring": false, // 4
      "securities": false, // 5
      "pledgeOneOff": false, // 6
      "donorAdvisedFund": false, // 7
    };

    for (let i = 0; i < cartContents.length; i++) {
      if (cartContents[i].type <= 2) {
        typesOfCartContents.recurring = true;
      }
      if (cartContents[i].type === 3) {
        typesOfCartContents.oneTime = true;
      }
      if (cartContents[i].type === 4) {
        typesOfCartContents.pledgeRecurring = true;
      }
      if (cartContents[i].type === 5) {
        typesOfCartContents.securities = true;
      }
      if (cartContents[i].type === 6) {
        typesOfCartContents.pledgeOneOff = true;
      }
      if (cartContents[i].type === 7) {
        typesOfCartContents.donorAdvisedFund = true;
      }
    }
    return typesOfCartContents;
  }

  containsAnonymous() {
    for (let cartItem of this.cartContents) {
      if (cartItem.options
        && cartItem.options.anonymous
        && cartItem.options.anonymous.selected) {
        return true;
      }
    }
    return false;
  }


  // SD: pledges are currently not right, so put in these
  // contains/is methods so we can test. These are the two types
  // that have the frequency set correctly
  containsRecurringPledgeWithOneTimePayment() {
    for (let cartItem of this.cartContents) {
      if (this.isRecurringPledgeWithOneTimePayment(cartItem)) {
        return true;
      }
    }
    return false;
  }

  isRecurringPledgeWithOneTimePayment(cartItem) {
    if (cartItem.type == 6) {
      return true;
    }
    return false;
  }


  containsRecurringPledgeWithAutoCharge() {
    for (let cartItem of this.cartContents) {
      if (this.isRecurringPledgeWithAutoCharge(cartItem)) {
        return true;
      }
    }
    return false;
  }

  setFoundItem(value) {
    this.foundItem = value;
  }

  getFoundItem() {
    return this.foundItem;
  }

  clearFoundItem() {
    this.foundItem = undefined;
  }

  isRecurringPledgeWithAutoCharge(cartItem) {
    if (cartItem.type == 4) {
      return true;
    }
    return false;
  }


  isPlannedGift(cartItem) {
    let plannedGift = _.get(cartItem, 'options.planned.selected', false);
    return plannedGift;
  }


  /**
   * The amount that will be sent to cybersource (so not total pledge amount)
   * @param cartItem
   * @return {*}
   */
  getCyberSourceAmount(cartItem) {
    if (
      angular.isUndefined(cartItem.otherGift)
      || cartItem.otherGift === null
      || cartItem.type === 7 /* DAF */
    ) {
      let amount = this.parseIntWithCommas(cartItem.amount, 10);
      return amount;
    } else if (this.isRecurringPledgeWithAutoCharge(cartItem)) {
      let totalPledge = cartItem.otherGift.pledge.totalValue;
      let numPayments = this.getNumPaymentsPerYearForPledge(cartItem);
      let totalPledgeCents = totalPledge * 100;
      let recurringAmountCents = Math.ceil(totalPledgeCents / numPayments);
      let recurringAmountDollars = recurringAmountCents / 100;
      return recurringAmountDollars;
    } else if (this.isRecurringPledgeWithOneTimePayment(cartItem)) {
      return cartItem.otherGift.pledge.initial_payment_amount;
    }
    return 0;
  }

  /**
   * @param freqNumber {number}
   * @return {string}
   */
  freqNumberToCharCode(freqNumber) {
    let charCode;
    switch (freqNumber) {
      case 0:
        charCode = 'W';
        break;
      case 1:
        charCode = 'M';
        break;
      case -1:
        charCode = 'Q';
        break;
      case 2:
        charCode = 'Y';
        break;
    }
    return charCode;
  }

  charCodeToFreqNumber(charCode) {
    let freqNumber;
    switch (charCode) {
      case 'W':
        freqNumber = 0;
        break;
      case 'M':
        freqNumber = 1;
        break;
      case 'Q':
        freqNumber = -1;
        break;
      case 'Y':
        freqNumber = 2;
        break;
    }
    return freqNumber;
  }
}

export default angular.module('givingApp.cartMemory', [])
  .service('cartMemory', cartMemoryService)
  .name;
