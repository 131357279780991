/**
 * Created by samd on 8/1/17.
 */

export default class Header {

  /** @type {!number} */
  gift_id = -1;

  /**
   * Money: 99.99
   * @type {?number}
   */
  total_amount;

  /** @type {?string} */
  last_name;

  /** @type {?string} */
  first_name;

  /** @type {?string} */
  middle_initial;

  /** @type {?string} */
  title;

  /** @type {?string} */
  name_suffix;

  /** @type {?string} */
  address_type;

  /** @type {?string} */
  street1;

  /** @type {?string} */
  street2;

  /** @type {?string} */
  city;

  /** @type {?string} */
  country;

  /** @type {?string} */
  state_province;

  /** @type {?string} */
  phone;

  /** @type {?string} */
  zip_postal_code;

  /** @type {?string} */
  //fax;

  /** @type {?string} */
  email;

  /** @type {?string} */
  //affil_alumn;

  /** @type {?string} */
  //affil_parent;

  /** @type {?string} */
  //affil_student;

  /** @type {?string} */
  //affil_staff;

  /** @type {?string} */
  //affil_friend;

  /** @type {?string} */
  alumni_degree_year;

  /** @type {?string} */
  //alumni_school;

  /** @type {?string} */
  gift_match_source;

  /** @type {?string} */
  //gift_credit_goes_to;

  /** @type {?string} */
  gift_in_memory_of;

  /** @type {?string} */
  country_of_citizenship;

  /** @type {?string} */
  gift_in_honor_of;

  /** @type {?string} */
  //gift_credit_to_affiliation;

  /** @type {?string} */
  //gift_credit_to_degree_year;

  /** @type {?string} */
  //gift_credit_to_school;

  /** @type {?string} */
  payment_reference_id;

  /** @type {?string} */
  payment_authorization_code;

  /** @type {?string} */
  receipt_sw;

  /** @type {?Date} */
  //date_row_created;

  /** @type {?Date} */
  //date_row_created_pst;

  /** @type {?string} */
  //donor_affil_orig;

  /** @type {?Date} */
  //date_sent_to_gifts;


  /** @type {?string} */
  //mfund_email_status;


  /** @type {?string} */
  //member_name1;


  /** @type {?string} */
  //member_name2;

  /** @type {?string} */
  //num_children;

  /** @type {?string} */
  //member_address;

  /** @type {?string} */
  //card_name;

  /** @type {?string} */
  //card_address;

  /** @type {?string} */
  //new_member;

  /** @type {?Date} */
  //hbm_version;

  /** @type {?string} */
  donation_source;

  /** @type {?string} */
  //membership_amount_category;

  /** @type {?string} */
  broker_name;

  /** @type {?string} */
  brokerage_bank_name;

  /** @type {?string} */
  broker_email;

  /** @type {?string} */
  broker_phone;

  /** @type {?string} */
  payment_card_type;

  /** @type {?string} */
  order_number;

  /** @type {?string} */
  //update_atlas;

  /** @type {?string} */
  uk_citizen;

  /** @type {?string} */
  uk_gift_aid;

  /** @type {?string} */
  //gk_type;

  /** @type {?string} */
  //gk_rec_name;

  /** @type {?string} */
  //gk_rec_address;

  /** @type {?string} */
  //gk_rec_phone;

  /** @type {?string} */
  //gk_rec_email;

  /** @type {?string} */
  //gk_rec_status;

  /** @type {?Date} */
  //gk_rec_sent_date;

  /** @type {?string} */
  currency_ind;

  /** @type {?Date} */
  // not our concern
  //etl_date_time;

  /** @type {?number} */
  browser_timezone_offset_minutes;

  /** @type {string} */
  payment_source;

  /**
   * Sent over with Stripe payment intent headers
   * @type {string}
   */
  anonymous;

  /**
   * This will only be in responses - to be sent to cybersource
   * @type {number|undefined}
   */
  transaction_uuid;

  /**
   * For logging on with posts - only for input, not returned by post
   * @type {?object | undefined}
   */
  appState;
}
