'use strict';

import angular from 'angular';
// import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import 'ngstorage';
import ngMessages from 'angular-messages';


import uiRouter from '@uirouter/angularjs';
import uiBootstrap from 'angular-ui-bootstrap';

import {
  routeConfig
} from './app.config';


import constants from './app.constants';
import util from '../components/util/util.module';
import GivingService from '../app/givingService/givingService.service';
import DataLoader from './dataLoader/dataLoader.service';

// components
import navbar from '../components/navbar/navbar.component';
import helpbar from '../components/helpbar/helpbar.component';
import dropdown from '../components/dropdown/dropdown.component';
import navDropdown from '../components/navDropdown/navDropdown.component';
import footer from '../components/footer/footer.component';
import main from './main/main.component';
import HeroImage from '../components/hero-image/hero-image.component';
import FundHighlightComponent from '../components/fund-highlight/fund-highlight.component';
import StepIndicator from '../components/step-indicator/step-indicator.component';
import BackgroundImage from '../components/background-image/background-image.component';
import SearchFund from '../components/searchFund/searchFund.component';
import ShortIndicator from '../components/short-indicator/short-indicator.component';
import cart from '../components/cart/cart.component';
import personalInformation from '../components/personal-information/personal-information.component';
import fundTile from '../components/fund-tile/fund-tile.component';
import modal from '../components/modal/modal.component';
import AreaProgram from '../components/areaProgram/areaProgram.component';
import FundTileLoaderComponent from '../components/fund-tile-loader/fund-tile-loader.component';
import giftOptions from '../components/giftOptions/giftOptions.component';
import stripe from '../components/stripe/stripe.component';
import currencyDropdown from '../components/currencyDropdown/currencyDropdown.component';

// filters
import frequencyLabel from './filters/frequencyLabel/frequencyLabel.filter';
import currencyNonzeroCents from './filters/currencyNonzeroCents/currencyNonzeroCents.filter';

// routes
import fund from './routes/fund/fund.component';
import findFund from './routes/find-fund/find-fund.component';
import personalDetails from './routes/personal-details/personal-details.component';
import search from './routes/search/search.component';
import thankYou from './routes/thank-you/thank-you.component';

// services
import cartMemory from './services/cartMemory/cartMemory.service';
import frequencyCalculator from './services/frequencyCalculator/frequencyCalculator.service';
import currencySymbol from './services/currencySymbol/currencySymbol.service';
import userData from './services/userData/userData.service';
import checkout from './services/checkout/checkout.service';
import givingService from './givingService/givingService.service';
import simpleModal from './simpleModal/simpleModal';

import './app.scss';

// one per line to avoid git conflicts
angular.module('givingApp', [
  ngCookies,
  ngResource,
  ngSanitize,
  uiRouter,
  uiBootstrap,
  navbar,
  helpbar,
  dropdown,
  navDropdown,
  findFund,
  footer,
  main,
  HeroImage,
  FundHighlightComponent,
  StepIndicator,
  BackgroundImage,
  SearchFund,
  ShortIndicator,
  cart,
  personalDetails,
  search,
  thankYou,
  personalInformation,
  frequencyLabel,
  currencyNonzeroCents,
  fund,
  cartMemory,
  frequencyCalculator,
  currencySymbol,
  userData,
  constants,
  util,
  'ngStorage',
  DataLoader,
  GivingService,
  fundTile,
  modal,
  AreaProgram,
  FundTileLoaderComponent,
  giftOptions,
  checkout,
  ngMessages,
  stripe,
  currencyDropdown,
  givingService,
  simpleModal,
])
  .config(routeConfig)
  .run(function($rootScope, $location, $http, dataLoader, $window) {//}, $state) {
    'ngInject';

    // If running http, redirect to https
    var url = $location.absUrl();
    var local = $location.host().indexOf('localhost') > -1;
    var https = $location.protocol().indexOf('https') > -1;

    // assumed that !local ==> aws (or some other container host)
    if (!local && !https) {
      url = url.replace('http:', 'https:');
      // console.log('client redirect to '+url);
      $window.location.href = url;
      return;
    }
    // GIVING-492: moved this into main.routes.js - see comment there
    // just to be safe, restrict dataLoader startup to local/http, aws/https
    // if ( (local && !https) || (!local || https) ) {
    //   dataLoader.loadCaches();
    // }
    $rootScope.$on('$stateChangeStart', function(event, next) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });

  });

angular.element(document)
  .ready(() => {
    angular.bootstrap(document, ['givingApp'], {
      strictDi: true
    });
  });
