'use strict';
import angular from 'angular';

export class backgroundImageComponent {

  /**
   * An image url.
   *
   * @type {string}
   */
  image;

  constructor($scope, Util) {
    'ngInject';
    this.$scope = $scope;
    this.util = Util;
  }

  $onChanges(changes) {
    //Use default image if needed. Probably better to just inject default image like we do fund images! Grant Bajere
    if(angular.isUndefined(this.image) || this.image === null){

      //TODO: adjust to make more dynamic
      this.backgroundImage = this.util.getImageUrlMobileOrDesktop('1001_tiles_mobile', '1001_tiles_desktop');
      // this.$http.get('api/XXX/default-image').then(response => {
      //     this.backgroundImage = response.data;
      // });
    } else {
      this.backgroundImage = this.image;
    }
  }

}

export default angular.module('givingApp.background-image', [])
  .component('backgroundImage', {
    template: require('./background-image.html'),
    bindings: {
      image: '<'
    },
    controller: backgroundImageComponent
  })
  .name;
