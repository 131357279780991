'use strict';
const angular = require('angular');


import DonationFund from "../givingService/DonationFund";
import CyberSourceConfig from "../givingService/CyberSourceConfig";
import DonationGiftingTile from "../givingService/DonationGiftingTile";
import ControlParam from "../givingService/ControlParam";
import Amount from "../givingService/Amount";
import StateCode from "../givingService/StateCode";
import CountryCode from "../givingService/CountryCode";
import DonationGroup from "../givingService/DonationGroup";
import DonationSchool from "../givingService/DonationSchool";
import DonationSpotlightFund from "../givingService/DonationSpotlightFund";
import DonationImageSchool from "../givingService/DonationImageSchool";

var sessionStore = sessionStorage;

export class DataLoader {
  /** could use this to inform users to use another browser */
  hasSessionStore = sessionStore ? true : false;

  /** @type {!boolean} */
  loaded = false;

  /** @type {!DonationFund[]} */
  funds = [];

  /** @type {!DonationFund[]} */
  selectedFunds = [];

  /** @type {!Amount[]} */
  amounts = [];

  /** @type {!ControlParam[]} */
  controlParams = [];

  /** @type {!DonationGiftingTile[]}*/
  donationGiftingTiles = [];

  /** @type {!CountryCode[]} */
  countryCodes = [];

  /** @type {!StateCode[]} */
  stateCodes = [];


  /** @type {!DonationGroup[]} */
  donationGroups = [];

  /** @type {!DonationSchool[]} */
  donationSchools = [];

  /** @type {!DonationSpotlightFund[]} */
  donationSpotlightFunds = [];

  /** @type {!DonationImageSchool[]} */
  donationImageSchools = [];

  /** @type {!CyberSourceConfig} */
  cyberSourceConfig;

  /** @type {!StripeConfig} */
  stripeConfig;

  /** @type {RecaptchaConfig} */
  recaptchaConfig;

  /** @type {ChariotConfig} */
  chariotConfig;

  /** @type {!ExchangeRate[]} */
  exchangeRates = [];

  executingPromise;

  /*@ngInject*/
  constructor($http, $q, Util, $rootScope, $interval, $location, $sessionStorage, $timeout) {
    this.$http = $http;
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$interval = $interval;
    this.util = Util;
    this.$location = $location;
    this.$sessionStorage = $sessionStorage;
    this.$timeout = $timeout;
  }

  resetData() {
    this.funds = [];
    this.selectedFunds = [];
    this.amounts = [];
    this.controlParams = [];
    this.donationGiftingTiles = [];
    this.countryCodes = [];
    this.stateCodes = [];
    this.donationGroups = [];
    this.donationSchools = [];
    this.donationSpotlightFunds = [];
    this.donationImageSchools = [];
    this.cyberSourceConfig = undefined;
    this.stripeConfig = undefined;
    this.recaptchaConfig = undefined;
    this.chariotConfig = undefined;
    this.exchangeRates = undefined;
  }

  loadExchangeRates() {
    return this.$http.get('/api/currency_conversion/')
      .then(response => {
        response.data.map(item => {
          this.exchangeRates.push(item);
        });
      })
      .catch(err => {
        // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
        let error = new Error('error contacting the server loading currency_conversion ' + err.statusText);
        return this.$q.reject(error);
      });
  }

  /**
   * @param {string} currencyCode
   * @return {ExchangeRate|undefined}
   */
  getExchangeRate(currencyCode) {
    return this.exchangeRates.find(
      exchangeRate => {
        return exchangeRate.currency_code === currencyCode;
      });
  }

  loadCyberSourceConfig() {
    return this.$http.get(
      '/api/cyberSource/config'
    ).then(response => {
      this.cyberSourceConfig = Object.assign(new CyberSourceConfig(), response.data);
    }).catch(err => {
      // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
      let error = new Error('error contacting the server loading cs config' + err.statusText);
      return this.$q.reject(error);
    });
  }


  loadAppConfig() {
    return this.$http.get(
      '/api/app_config/'
    ).then(response => {
      /** @type {AppConfig}*/
      let appConfig = response.data;
      this.stripeConfig = appConfig.stripe;
      this.recaptchaConfig = appConfig.recaptcha;
      this.chariotConfig = appConfig.chariot;
    }).catch(err => {
      let error = new Error('error contacting the server loading app config' + err.statusText);
      return this.$q.reject(error);
    });
  }


  loadFunds() {
    if (this.funds.length > 0) {
      let deferred = this.$q.defer();
      deferred.resolve(this.funds);
      return deferred.promise;
    } else {

      return this.$http.get('/api/donation_fund/')
        .then(response => {
          this.funds = [];
          response.data.map(item => {
            let fund = Object.assign(new DonationFund(), item);
            this.funds.push(fund);
          });
          return this.funds;
        })
        .catch(err => {
          let error = new Error('error contacting the server loading donation_fund ' + err.statusText);
          return this.$q.reject(error);
        });
    }
  }


  loadAmounts() {
    return this.$http.get('/api/amount/')
      .then(response => {
        response.data.map(item => {
          let amount = Object.assign(new Amount(), item);
          amount.amount = parseFloat(amount.amount);
          this.amounts.push(amount);
        });
        return this.amounts;
      })
      .catch(err => {
        // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
        let error = new Error('error contacting the server loading amounts ' + err.statusText);
        return this.$q.reject(error);
      });
  }

  loadControlParams() {
    return this.$http.get('/api/control_param/')
      .then(response => {
        response.data.map(item => {
          let controlParam = Object.assign(new ControlParam(), item);
          this.controlParams.push(controlParam);
        });
        return this.controlParams;
      })
      .catch(err => {
        // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
        let error = new Error('error contacting the server loading control params ' + err.statusText);
        return this.$q.reject(error);
      });
  }

  getCountryForCode(_code) {
    if (!_code) {
      return null;
    }
    if (_code.length == 2) {
      return this.countryCodes.find(function (countryRecord) {
        return countryRecord.country_code == _code;
      });
    }
    if (_code.length == 3) {
      return this.countryCodes.find(function (countryRecord) {
        return countryRecord.country_code_3char == _code;
      })
    }
  }


  getStateByCodeAndCountry(_stateCode, countryRecord) {
    if (!_stateCode || !countryRecord) {
      return null;
    }
    let result = this.stateCodes.find(function (stateRecord) {
      return _stateCode == stateRecord.state_code && stateRecord.country_code == countryRecord.country_code;
    });

    return result;
  }

  filterPhoneCharacters(phoneCharacters) {
    if (phoneCharacters) {
      phoneCharacters = phoneCharacters.replace('/', '-');
      phoneCharacters = phoneCharacters.trim();
    }
    return phoneCharacters;
  }

  loadCountryCodes() {
    return this.$http.get('/api/country_code/')
      .then(response => {
        response.data.map(item => {
          /** @type {!CountryCode} */
          let countryCode = Object.assign(new CountryCode(), item);
          // adding these redundant properties so we can use this.countryCodes as-is with TB code
          countryCode.name = countryCode.name;
          countryCode.code = countryCode.code;
          this.countryCodes.push(countryCode);
        });
        return this.countryCodes;
      })
      .catch(err => {
        // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
      });
  }


  loadDonationGroups() {
    if (this.donationGroups.length > 0) {
      let deferred = this.$q.defer();
      deferred.resolve(this.donationGroups);
      return deferred.promise;
    } else {
      return this.$http.get('/api/donation_group/')
        .then(response => {
          this.donationGroups = [];
          response.data.map(item => {
            let donationGroup = Object.assign(new DonationGroup(), item);
            this.donationGroups.push(donationGroup);
          });
          return this.donationGroups;
        })
        .catch(err => {
          // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
          let error = new Error('error contacting the server loading donation groups ' + err.statusText);
          return this.$q.reject(error);
        });
    }
  }

  loadDonationSchools() {
    if (this.donationSchools.length > 0) {
      let deferred = this.$q.defer();
      deferred.resolve(this.donationSchools);
      return deferred.promise;
    } else {
      return this.$http.get('/api/donation_school/')
        .then(response => {
          this.donationSchools = [];
          response.data.map(item => {
            let donationSchool = Object.assign(new DonationSchool(), item);
            this.donationSchools.push(donationSchool);
          });
          return this.donationSchools;
        })
        .catch(err => {
          // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
          let error = new Error('error contacting the server loading donation school ' + err.statusText);
          return this.$q.reject(error);
        });
    }
  }

  loadStateCodes() {
    return this.$http.get('/api/state_code/')
      .then(response => {
        response.data.map(item => {
          /** @type {!StateCode} */
          let stateCode = Object.assign(new StateCode(), item);
          this.stateCodes.push(stateCode);
        });
      })
      .catch(err => {
        // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
      });
  }

  /**
   * Load the DonationGiftingTile's. Will only load them if they aren't there already.
   *
   * @return {this.$q.<(DonationGiftingTile[]|Error)>} the results or an Error
   */
  loadDonationGiftingTiles() {
    if (this.donationGiftingTiles.length > 0) {
      let deferred = this.$q.defer();
      deferred.resolve(this.donationGiftingTiles);
      return deferred.promise;
    } else {
      return this.$http.get('/api/donation_gifting_tiles/')
        .then(response => {
          this.donationGiftingTiles = [];
          response.data.map(item => {
            let donationGiftingTile = Object.assign(new DonationGiftingTile(), item);
            donationGiftingTile.util = this.util;
            this.donationGiftingTiles.push(donationGiftingTile);
          });
          return this.donationGiftingTiles;
        })
        .catch(err => {
          // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
          let error = new Error('error contacting the server loading donation gifting tiles ' + err.statusText);
          return this.$q.reject(error);
        });
    }
  }

  loadDonationSpotlightFunds() {
    return this.$http.get('/api/donation_spotlight_fund/')
      .then(response => {
        response.data.map(item => {
          let itemObject = Object.assign(new DonationSpotlightFund(), item);
          this.donationSpotlightFunds.push(itemObject);
        });
        return this.donationSpotlightFunds;
      })
      .catch(err => {
        // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
        let error = new Error('error contacting the server loading donation_spotlight_funds' + err.statusText);
        return this.$q.reject(error);
      });
  }


  loadDonationImageSchools() {
    if (this.donationImageSchools.length > 0) {
      let deferred = this.$q.defer();
      deferred.resolve(this.donationImageSchools);
      return deferred.promise;
    } else {
      return this.$http.get('/api/donation_image_school/')
        .then(response => {
          response.data.map(item => {
            let itemObject = Object.assign(new DonationImageSchool(), item);
            this.donationImageSchools.push(itemObject);
          });
          return this.donationImageSchools;
        })
        .catch(err => {
          // console.error("fetch errored: \n" + JSON.stringify(err, 4, 4));
          let error = new Error('error contacting the server loading donation_image_schools' + err.statusText);
          return this.$q.reject(error);
        });
    }
  }


  /**
   * @param schoolCode {!string}
   * @return {!DonationSchool}
   * @throws {Error} if there's no such school
   */
  findSchoolBySchoolCode(schoolCode) {
    for (let donationSchool of this.donationSchools) {
      if (donationSchool.school_code === schoolCode) {
        return donationSchool;
      }
    }
    // let error = new Error("no donation school with code [" + schoolCode + "]");
    // throw error;
  }

  getDonationGiftingTiles() {
    return this.loadDonationGiftingTiles()
      .then(response => {
        let donationGiftingTiles = []
        for (/** @type {DonationGiftingTile} */ let donationGiftingTile of response) {
          if (donationGiftingTile.school_code) {
            donationGiftingTile.group_code =
              this.findSchoolBySchoolCode(donationGiftingTile.school_code).group_code;
          }
          donationGiftingTiles.push(donationGiftingTile);
        }
        return donationGiftingTiles;
      });
  }

  /**
   * Get DonationFundTiles with the given imageType.
   *
   * Results will be ordered by list_order.
   *
   * @param {!string} imageType the type of image, eg 'TILE' or 'HERO'
   *
   * @returns {this.$q.<(DonationGiftingTile[]|Error)>} the results or
   * an Error.
   */
  findDonationGiftingTiles(imageType) {
    return this.getDonationGiftingTiles()
      .then(response => {
        let donationGiftingTiles = [];
        for (/** @type {DonationGiftingTile} */ let donationGiftingTile of response) {
          if (donationGiftingTile.image_type === imageType) {
            donationGiftingTiles.push(donationGiftingTile);
          }
        }
        return donationGiftingTiles;
      });
  }

  loadDelay(millis) {
    return this.$interval(() => {
    }, millis, 1)
  }

  /**
   * Our 'official' case insensitive match for dataLoader.funds (DonationFund's),
   * used in multiple places.
   *
   * @param substring
   * @param results optional array, if present matches are appended, else a
   * a new empty array is used
   * @return an array with zero or more matches
   */
  findMatchingFunds(substring, results) {
    results = results ? results : [];
    let regex = new RegExp(substring, 'i');
    for (let fund of this.funds) {
      if (regex.test(fund.fund_name)) {
        results.push(fund);
      }
    }
    return results;
  }


  getFromStorage() {
    let json = sessionStore.dataLoaderJson;
    if (!json) {
      return null;
    }
    let _data = JSON.parse(json);
    _.merge(this, _data);
    this.donationImageSchools = this.donationImageSchools.map((item) => {
      return Object.assign(new DonationImageSchool(), item);
    });
    this.donationGroups = this.donationGroups.map((item) => {
      return Object.assign(new DonationGroup(), item);
    });
    this.donationSchools = this.donationSchools.map((item) => {
      return Object.assign(new DonationSchool(), item);
    });
    this.loaded = true;
    return _data;
  }

  setToStorage() {
    let serializableData = {
      // funds: this.funds, GIVING-492
      selectedFunds: this.selectedFunds,
      amounts: this.amounts,
      controlParams: this.controlParams,
      donationGiftingTiles: this.donationGiftingTiles,
      countryCodes: this.countryCodes,
      stateCodes: this.stateCodes,
      donationGroups: this.donationGroups,
      donationSchools: this.donationSchools,
      donationSpotlightFunds: this.donationSpotlightFunds,
      cyberSourceConfig: this.cyberSourceConfig,
      stripeConfig: this.stripeConfig,
      chariotConfig: this.chariotConfig,
      recaptchaConfig: this.recaptchaConfig,
      exchangeRates: this.exchangeRates,
      donationImageSchools: this.donationImageSchools,
    };
    let json = JSON.stringify(serializableData);
    sessionStore.dataLoaderJson = json;
    this.loaded = true;
  }

  /**
   * Create an array of promises to reload the cache
   * @returns {Promise[]}
   */
  prepareLoad() {
    let loaders = [];
    // loaders.push(this.loadFunds()); GIVING-492
    loaders.push(this.loadAmounts());
    loaders.push(this.loadControlParams());
    loaders.push(this.loadDonationGiftingTiles());
    loaders.push(this.loadCountryCodes());
    loaders.push(this.loadDonationGroups());
    loaders.push(this.loadDonationSchools());
    loaders.push(this.loadStateCodes());
    loaders.push(this.loadDonationSpotlightFunds());
    loaders.push(this.loadDonationImageSchools());
    loaders.push(this.loadCyberSourceConfig());
    loaders.push(this.loadAppConfig());
    loaders.push(this.loadExchangeRates());
    return loaders;
  }

  /**
   * Execute the loaders
   * @param loaders
   */
  executeLoaders(loaders) {
    this.loaded = false;
    // view/style the waiting page by uncommenting this
    //loaders.push(this.loadDelay(9000));
    this.executingPromise = this.$q.all(loaders)
      .then(() => {
        this.setToStorage(true);
        this.executingPromise = null;

        // GIVING-492 - original code had a digest here (I assume to make sure sessionStorage is updated),
        // but that made this untestable (digest in progress errors on $httpBackend.flush().
        // This should do the same thing
        this.$rootScope.$evalAsync();
      });
    return this.executingPromise;
  }

  /**
   * @type Promise
   */
  loadFundsPromise = null;

  executeLoadFundsPromise() {
    if (this.loadFundsPromise === null) {
      this.loadFundsPromise = this.loadFunds();
    }
  }

  loadCaches() {
    this.executeLoadFundsPromise(); // GIVING-492
    return this.loadCachesNoFunds();
  }

  // GIVING-492
  loadCachesNoFunds() {
    if (this.executingPromise) {
      return this.$q.all([this.executingPromise, this.loadFundsPromise]);
    }
    if (this.loaded === true || this.getFromStorage()) {
      return this.$q.all([this.$q.resolve(true), this.loadFundsPromise]);
    }
    let loaders = this.prepareLoad();
    return this.$q.all([this.executeLoaders(loaders), this.loadFundsPromise]);
    // // view/style the waiting page by uncommenting this
    // //loaders.push(this.loadDelay(9000));
    // return this.$q.all(loaders).then(() => { this.loaded = true; }).then( () => { this.$rootScope.$digest() });
  }

  isReady() {
    return this.loaded && this.funds.length > 0;
  }

  formatDate(date) {
    let mm = date.getMonth() + 1; // getMonth() is zero-based
    let dd = date.getDate();

    return [date.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd
    ].join('');
  }

  /**
   * Get all control params for a given parameter.
   *
   * @param parameter {string}
   * @return {Array.<ControlParam>}
   */
  getControlParams(parameter) {
    // assume list order
    return this.controlParams.filter(controlParam => {
      return controlParam.parameter == parameter;
    });
  }


  /**
   * Get all control params.
   * @return {Array.<ControlParam>}
   */
  getAllControlParams() {
    return this.controlParams;
  }

  checkCurrentCurrencyItem(currency) {
    let loopZeroDecimal = this.getAllControlParams();
    let foundItems = [];
    for (let i = 0; i < loopZeroDecimal.length; i++) {
      if (loopZeroDecimal[i].value === currency.code) {
        foundItems.push(loopZeroDecimal[i]);
      }
    }
    return foundItems;
  }




  /**
   * Get the control param for a particular
   * parameter in value. Returns first one found, or
   * undefined if there's no match
   *
   * @param parameter {string}
   * @return ControlParam
   */
  getControlParam(parameter, value) {
    // assume list order
    return this.controlParams.find(
      controlParam => {
        if (controlParam.parameter == parameter
          && controlParam.value == value) {
          return true;
        }
        return false;
      }
    )
  }


}

export default angular.module('givingApp.dataLoader', [])
  .service('dataLoader', DataLoader)
  .name;
