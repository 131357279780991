'use strict';
import angular from "angular";
import 'ngstorage';

export class cartComponent {

  /** @ngInject */
    constructor($scope, $sessionStorage, $location, cartMemory, currencySymbol) {
        this.$scope = $scope;
        this.$sessionStorage = $sessionStorage;
        this.cartMemoryService = cartMemory;
        this.$location = $location;
        this.currencySymbolService = currencySymbol;
        this.shortcut = false;

    }

    remove = (id) => {
        this.cartMemoryService.remove(id);
        this.cartTotals = this.cartMemoryService.getTotals();

        this.$scope.$emit('cartChanged');

        if((this.cart.length === 0) && ((this.$location.path() === "/login") || (this.$location.path() === "/payment"))) {
            this.$location.url('/find-fund');
            this.cartMemoryService.clearFoundItem();
        }
    }

    toggleCart = () => {
        if (this.showMobileCart === true) {
            this.showMobileCart = false;
        } else {

            if (this.cart.length !== 0) {
                this.showMobileCart = true;
            }

        }
    }

    $onInit() {

        this.currency = this.currencySymbolService.get();
        this.cart = this.cartMemoryService.retrieve();
        this.cartTotals = this.cartMemoryService.getTotals();
        this.showMobileCart = false;
        if((this.cart.length !== 0) && ((this.$location.path() === "/find-fund") || (this.$location.path() === "/fund"))) {
            this.shortcut = true;
        } else {
            this.shortcut = false;
        }
    }

    /**
     * Will take a string or number and return it as a number - template wants a number
     */
    parseIntWithCommas(intWithCommas) {
      return this.cartMemoryService.parseIntWithCommas(intWithCommas);
    }

  checkZeroDecimal() {
    let foundItem = this.cartMemoryService.getFoundItem();
    if (foundItem && foundItem.parameter === "ZERO_DECIMAL") {
      return true;
    } else {
      return  false;
    }
  }

}

export default angular.module('givingApp.cart', []).component('cart', {
    template: require('./cart.html'),
    controller: cartComponent
}).name;
