'use strict';
/* eslint no-sync: 0 */

import angular from 'angular';

export class NavbarComponent {
  constructor() {
    'ngInject';
  }


  $onInit() {
    this.showHelp = false;
  }
}

export default angular.module('directives.navbar', []).component('navbar', {
  template: require('./navbar.html'),
  controller: NavbarComponent
}).name;


// 'use strict';
// /* eslint no-sync: 0 */
//
// import angular from 'angular';
// import {SimpleModal} from '../../app/simpleModal/simpleModal';
//
//
// export class NavbarComponent {
//   menu = [{
//     title: 'Home',
//     state: 'main'
//   }];
//
//   isCollapsed = true;
//
//   constructor(Auth, $rootScope, $uibModal) {
//     'ngInject';
//
//     this.isLoggedIn = Auth.isLoggedInSync;
//     this.isAdmin = Auth.isAdminSync;
//     this.getCurrentUser = Auth.getCurrentUserSync;
//     this.simpleModal = new SimpleModal($rootScope, $uibModal);
//   }
//
//   logoutAlert() {
//     var options = {
//       header: 'Close browser to log out',
//       size: 'sm',
//       message: 'You must close your browser so that we can ensure you are fully logged out of all instances of the Penn system',
//       okText: 'Got it',
//       callback: function (err, data) {
//         console.log('logout modal response = ' + data)
//       }
//     };
//     this.simpleModal.showAlert(options);
//   }
//
// }
//
// export default angular.module('directives.navbar', [])
//   .component('navbar', {
//     template: require('./navbar.html'),
//     controller: NavbarComponent
//   })
//   .name;
