/**
 * Created by samd on 7/25/17.
 */

'use strict';

export default class CyberSourceConfig {
  orderFormUrl;

  onlineGivingMerchantID;
  secureAcceptanceProfile;
  secureAcceptanceKey;

  onlineGivingMerchantIDUk;
  secureAcceptanceProfileUk;
  secureAcceptanceKeyUk;
}
