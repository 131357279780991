'use strict';

export default function($stateProvider) {
    'ngInject';
    $stateProvider.state('login', {
      url: '/login?pennkey&program&fund&amount&fastStart&hash&appeal&type',
      params: {
        pennkey: null,
        program: null,
        fund: null,
        amount: null,
        fastStart: null,
        hash: null,
        appeal: null,
        type: null
      },
      template: '<personal-details></personal-details>',
      resolve: {
        'DataLoader': function (dataLoader) {
          // helps with testing so you can reload the page and should
          // be harmless as far as performance since it's coming out of local storage at this point
          return dataLoader.loadCaches();
        }
      },
      onEnter: (cartMemory, $state) => {
        if (cartMemory.retrieve().length > 0) {
          // must have something in the cart to be on this page
        } else {
          $state.go('find-fund');
        }
      }
    });
}
