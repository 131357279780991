'use strict';
import angular from 'angular';

import uiRouter from '@uirouter/angularjs';

import routes from './thank-you.routes';
import {cartMemoryService} from "../../services/cartMemory/cartMemory.service";

export class ThankYouComponent {

  /** type {Object[]} */
  cart;

  userData;

  $stateParams;

  /** @type {!cartMemoryService} */
  cartMemory;

  $http;

  /** @type {!DataLoader} */
  dataLoader;

  /** @type {GivingService} */
  givingService;

  /** @type {boolean} */
  cyberSourceError = false;

  $sessionStorage;

  /** @type {boolean} */
  showDafContent = false;

  constructor(
    $stateParams,
    cartMemory,
    $location,
    userData,
    $http,
    dataLoader,
    currencySymbol,
    $sessionStorage,
    givingService) {
    'ngInject';
    this.$stateParams = $stateParams;
    this.cartMemory = cartMemory;
    this.$location = $location;
    this.userData = userData;
    this.$http = $http;
    this.dataLoader = dataLoader;
    this.currencySymbolService = currencySymbol;
    this.$sessionStorage = $sessionStorage;
    this.givingService = givingService;
  }

  done = () => {
    this.emptyCart();
    this.$location.url('/find-fund');
  };

  $onInit() {
    function formatMonth(month) {
      var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      return monthNames[month];
    }

    function formatDate(date) {
      var d = (date < 10) ? ("" + date).slice(-2) : date;
      return d;
    }

    this.country = this.userData.userInformation.address.countryCode;

    if (this.$stateParams.fastStart === 'simpleForm') {
      this.fastStart = true;
    }

    this.currency = this.currencySymbolService.get();
    this.showContentBasedOnCountry();

    if (this.$sessionStorage.donationSubmitted) {
      if (this.cartMemory.retrieve().length === 0) {
        throw new Error('should not happen: empty cart after submitted donation');
      }

      // don't process a cart unless it's because they got here
      // by submitting a donation - for example if they add items to the
      // cart then navigate directly to this page
      this.$sessionStorage.donationSubmitted = false;


      //wipe out any reload state left behind
      this.$sessionStorage.thankYouStateForReload = null;
      this.cart = this.cartMemory.retrieve();
      this.cartTotals = this.cartMemory.getTotals();
      var date = new Date();
      this.currentDate = formatMonth(date.getMonth()) + " " + formatDate(date.getDate()) + ", " + date.getFullYear();

      let reasonCode = this.$stateParams.reason_code;
      if (angular.isDefined(reasonCode) && reasonCode !== '100') {
        this.cyberSourceError = true;
      }
      this.emptyCart();
    } else {
      // should be a reload
      if (!this.$sessionStorage.thankYouStateForReload) {
        throw new Error('should not happen: non-donation submit, empty reload state');
      } else {
        /** @type {ThankYouStateForReload} */
        let stateForReload = this.$sessionStorage.thankYouStateForReload;
        this.cart = stateForReload.cart;
        this.cartTotals = stateForReload.cartTotals;
        this.currentDate = stateForReload.currentDate;
        this.cyberSourceError = stateForReload.cyberSourceError;
      }
    }
    this.showContentBasedOnSecurities();
    this.showDafContent = this.cart[0].type === 7;
    this.showSpecialProcess = this.showSpecialContent(this.cart);
    // this.showSpecialProcessingContent = ( iterate over this.cart and lookup each fund code in this.dataLoader.funds )
  }

  showSpecialContent(cart) {
    let special_process = false;
    for (let i = 0; i < cart.length; i++) {
      // console.log("cart");
      // console.log(cart[i]);
       for (let i = 0; i < cart.length; i++) {
          if (cart[i].special_processing && cart[i].special_processing === 'T') {
            return true;
          }
       }
    }
    return special_process;
  }

  showContentBasedOnCountry() {
    this.showUKContent = (this.country && this.country == "GB") ? true : false;
  }

  checkZeroDecimal() {
    let foundItem = this.cartMemory.getFoundItem();
    if (foundItem && foundItem.parameter === "ZERO_DECIMAL") {
      return true;
    } else {
      return  false;
    }
  }

  showContentBasedOnSecurities() {
    this.showSecuritiesContent = this.cart[0].otherGift && this.cart[0].otherGift.id === 5;
  }

  /**
   * Will take a string or number and return it as a number - template wants a number
   */
  parseIntWithCommas(intWithCommas) {
    return this.cartMemory.parseIntWithCommas(intWithCommas);
  }

  // We're done - empty the cart
  emptyCart() {
    // GIVING-279 = let's reset when the cart is empty
    this.$sessionStorage.backgroundImage = null;

    // to handle page reloads
    /** @type {ThankYouStateForReload} */
    let stateForReload = {};
    stateForReload.currentDate = this.currentDate;
    stateForReload.cart = this.cart;
    stateForReload.cartTotals = this.cartTotals;
    stateForReload.cyberSourceError = this.cyberSourceError;
    this.$sessionStorage.thankYouStateForReload = stateForReload;
    this.cartMemory.empty();
  }

}

export default angular.module('givingApp.thankYou', [uiRouter])
  .config(routes)
  .component('thankYou', {
    template: require('./thank-you.html'),
    controller: ThankYouComponent
  })
  .name;
