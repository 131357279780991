import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routes from './personal-details.routes';
import PersonBean from './personInfoBeans';
import ControlParam from "../../givingService/ControlParam";
import recaptcha from '../../../components/recaptcha/recaptcha.component';
import chariot from '../../../components/chariot/chariot.component';

/**
 *
 */
export class PersonalDetailsComponent {


  /** @type {FormController}  */
  personalInformation;

  userInformation;

  /** @type {!DataLoader} */
  dataLoader;

  /** @type {!cartMemoryService} */
  cartMemoryService;

  /**
   * To prevent multiple submits to cybersource.
   * @type {boolean}
   */
  submitEnabled = true;

  /** Data that will be send to cybersource */
  csData = {};

  $sessionStorage;

  /**
   * To Check CitizenShip selected .
   * @type {boolean}
   */
  shouldShowCitizenShip = false;

  /**
   * To Check CitizenShip selected .
   * @type {boolean}
   */
  shouldShowCitizenShipError = false;

  /** @type {!CheckoutService} */
  checkout;

  /** @type {currencySymbolService} */
  currencySymbolSvc;

  /** @type {ControlParam[]} */
  currencyControlParams = [];

  /**
   * @type {string}
   */
  stripePublishableKey;

  /**
   * @type {?StripeToken}
   */
  stripeToken = null;

  /**
   * @type {string}
   */
  recaptchaSitekey;

  /**
   * @type {GivingService}
   */
  givingService;

  /**
   * @type {?string}
   */
  recaptchaToken = null;

  /**
   * @type {?string}
   */
  recaptchaWidgetId = null;

  /** @type {?string} */
  chariotConnectId= null;

  /**
   * @type {?ChariotSuccessMetadata}
   */
  chariotSuccessMetadata = null;

  /*@ngInject*/
  constructor($http, $scope, $location, userData, Util, cartMemory, $window, $stateParams, $interval, dataLoader, $timeout, $sessionStorage,
              checkout, currencySymbol, givingService, $q) {
    this.$http = $http;
    this.$scope = $scope;
    this.$location = $location;
    this.userDataService = userData;
    this.UtilService = Util;
    this.cartMemoryService = cartMemory;
    this.$window = $window;
    this.$stateParams = $stateParams;
    this.dataLoader = dataLoader;
    this.$timeout = $timeout;
    this.$interval = $interval;

    this.showInputs = false;
    this.fetching = false;

    this.requestedPennId = false;

    this.localhost = this.$location.host().indexOf('localhost') > -1;

    this.$sessionStorage = $sessionStorage;

    this.checkout = checkout;
    this.currencySymbolSvc = currencySymbol;
    this.givingService = givingService;
    this.totals = undefined;

    this.$q = $q;
  }

  $onInit() {
    this.showInputs = false;
    this.shouldShowCitizenShipError = false;
    //Show vs hide for bank details
    let disabled = this.cartMemoryService.status();
    this.bank = disabled.securities;
    this.setBank = () => {
      disabled = this.cartMemoryService.status();
      this.bank = disabled.securities;
    };
    this.$scope.$on("cartChanged", this.setBank);

    this.currencyControlParams = this.dataLoader.getControlParams(ControlParam.CURRENCY_INDICATOR);
    this.stripePublishableKey = this.dataLoader.stripeConfig.publishableKey;
    this.recaptchaSitekey = this.dataLoader.recaptchaConfig.sitekey;
    this.chariotConnectId = this.dataLoader.chariotConfig.connectId;

    this.givingService.getGrecaptcha().then(grecaptcha => {
      this.grecaptcha = grecaptcha;
    });

    this.totals = this.cartMemoryService.getTotals();

    if (this.userDataService.get() !== false) {
      this.showInputs = true;
      return;
    }

    // Upon pennkey login our IDP will post back to node, which will redirect us to this URL with a pennkey param
    if (this.$stateParams.pennkey) {
      // console.log('initting a pennkey user');
      let userState = new PersonBean.userState();
      //this.showInputs = true;
      userState.isLoggedIn = true;
      this.processUser(userState);
      return;
    }
  }

  /**
   * @param {string} recaptchaToken the recaptcha token
   */
  recaptchaCallback(recaptchaToken) {
    this.recaptchaToken = recaptchaToken;
  }

  recaptchaExpiredCallback() {
    this.recaptchaToken = null;
  }

  setRecaptchaWidgetId(recaptchaWidgetId) {
    this.recaptchaWidgetId = recaptchaWidgetId;
  }

  resetRecaptcha() {
    this.grecaptcha.reset(this.recaptchaWidgetId);
  }

  triggerPenn() {
    let userState = new PersonBean.userState();
    this.requestedPennId = true;

    // for local testing only
    if (this.localhost) {
      userState.pennId = '84207404'; // has atlas record
      userState.isLoggedIn = true; // needed for local testing of pennId
      //userState.hash = 'jW3y3Y2h0fIFMnPRxgHBZL5S';
      //'jW3y3Y2h0fIFMnPRxgHBZL5S/3Ejqjc4e8VGXQaPpGzwvx2bdDNeSPBhi6WCDQFCrv2+jisM0pdB5M4DdkPH07vrC+CHf72Avo/uEvxquuv02wxbUK9lvN8yJ5t8QlMxQB0lmQ==';
      this.processUser(userState);
      return;
    }
    // standard pennkey login, requires shib setup (ie aws-only, not local)
    this.$window.location.href = '/auth/saml';
  };

  /**
   * If we have a hash value, call the api get /api/hash/:hash
   *
   * Hash value api returns this
   "value": Charfield,
   "expiration_date": DateTimeField
   *
   * @param userState
   * @returns {*}
   */
  checkHashApi(userState) {
    userState.execute = !!userState.hash;
    if (userState.execute) {
      // encodeURIComponent(hash) - allows special characters to be placed on the URL (see hash integration test)
      userState.encodedHash = encodeURIComponent(userState.hash);
      this.fetchMessage = 'Looking up your link';
      this.$timeout();
      return this.callHash(userState);
    }
    return this.$q.resolve(userState);
  }

  callHash = (userState) => {
    return this.$http.get('/api/hash/' + userState.encodedHash);
  };

  handleHashResponse(userState, response) {
    if (response && (userState !== response)) {
      this.$timeout();
      userState.hashResponse = response.data;
    }
    return this.$q.resolve(userState);
  }

  checkAtlasEmplId(userState) {
    userState.execute = !!userState.hashResponse;
    if (userState.execute) {
      this.fetchMessage = 'Looking up your employee ID';
      this.$timeout();
      return this.callAtlasWithEmplId();
    }
    return this.$q.resolve(userState);
  }

  callAtlasWithEmplId = () => {
    return this.$http.get('/api/giving_donor_pers_info/emplid/');
  };

  checkNodeUser(userState) {
    userState.execute = !userState.atlasResponse && userState.isLoggedIn;
    if (userState.execute) {
      this.fetchMessage = 'Getting your login information';
      this.$timeout();
      return this.callNodeUser();
    }
    return this.$q.resolve(userState);
  }

  callNodeUser = () => {
    return this.$http.get('/api/users/me');
  };

  handleNodeUser(userState, response, loggingPrefix) {
    this.logObject(response, loggingPrefix);
    if (response && (userState !== response)) {
      let user = userState.nodeUserResponse = response.data;
      userState.userInformation.first_name = user.name;
      userState.userInformation.last_name = user.meta.lastName;
      userState.userInformation.email = user.email;
      userState.pennId = user.meta.pennId;
      userState.pennKey = user.meta.pennKey;
    }
    return userState;
  }

  checkAtlasWithPennId(userState) {
    userState.execute = !!userState.pennId || userState.isLoggedIn;

    if (userState.execute) {
      this.fetchMessage = 'Trying to find your PennId in Alumni Relations';
      this.$timeout();
      return this.callAtlasWithPennId();
    }
    return this.$q.resolve(userState);
  }

  callAtlasWithPennId = () => {
    return this.$http.get('/api/giving_donor_pers_info/pennid/');
  };

  /**
   * Check the type of the variable
   * @param variable
   * @param typeName
   * @param loggingPrefix - if false console.log this string prefix to a descriptive error message,
   *        if undefined, mismatches will not be logged.
   * @return true or false
   */
  isTypeOf(variable, typeName, loggingPrefix) {
    let result = typeName == typeof variable;
    if (result == false && loggingPrefix) {
      // console.log(loggingPrefix + ' expected ' + typeName + ' got ' + typeof variable);
    }
  }

  /**
   * Our makeshift alternative to (response && response.status != 404), try to avoid using it.
   * @param response
   * @param loggingPrefix
   * @returns {boolean}
   */
  responseHasData(response, loggingPrefix) {
    if (this.isTypeOf(response, 'object', loggingPrefix)) {
      if (response.data && !response.data.detail) {
        return true;
      } else {
        if (loggingPrefix) {
          // console.log(loggingPrefix + ' treating response as 404, response.data.detail = ' + JSON.stringify(response.data.detail));
        }
      }

    }
    return false;
  }

  handleAtlas(userState, response, loggingPrefix) {
    this.logObject(response, loggingPrefix);
    // leave the above intact for debugging, use a compact method to determine what we do or don't have below
    if (response && response !== userState) {
      userState.atlasResponse = response.data;
      PersonBean.populateUserInformationFromAtlas(userState, this.dataLoader);
    }
    return userState;
  }

  checkPcom(userState) {
    var self = this;
    userState.execute = !userState.atlasResponse;
    if (userState.execute) {
      this.fetchMessage = 'Trying to locate you in Penn Community';
      this.$timeout();
      return this.callPcom();
    }
    return this.$q.resolve(userState);
  }

  callPcom = () => {
    return this.$http.get('/api/pcom/');
  };

  handlePcom(userState, response) {
    if (response && (userState !== response)) {
      userState.pcomResponse = response;
      let pcomData = response.data ? response.data.result_data : null;
      pcomData = pcomData.length > 0 && pcomData[0] ? pcomData[0] : null;

      let countryRecord = this.dataLoader.getCountryForCode(pcomData.country);
      let country = countryRecord ? countryRecord.country_name : pcomData.country;
      let stateRecord = this.dataLoader.getStateByCodeAndCountry(pcomData.state, countryRecord);
      let state = stateRecord ? stateRecord.state_name : pcomData.state;
      if (pcomData) {
        userState.pcomResponse = pcomData;
        userState.userInformation.address = {
          street_1: pcomData.street1,
          street_2: pcomData.street2,
          city: pcomData.city.trim(), // extra spaces could be problem for CS and form looks better without them
          zip: pcomData.zip.trim(), // extra spaces could be problem for CS and form looks better without them
          state: state,
          country: country
        };
        if (countryRecord) {
          userState.userInformation.address.countryCode = countryRecord.country_code;
        }
        if (stateRecord) {
          userState.userInformation.address.stateCode = stateRecord.state_code;
        }
      }
    }
    return userState;
  }

  /**
   * Convenience/shorthand method for optionally logging errors and returning
   * to our promise chain (by returning userState).
   * @param err
   * @param userState
   * @param loggingPrefix
   * @return {*}
   */
  catchAndRelease(err, userState, loggingPrefix) {
    this.logObject(err, loggingPrefix);
    return userState;
  }

  // logResponse(response, loggingPrefix) {
  //   if (loggingPrefix) {
  //     this.logObject(response.status, loggingPrefix + 'status ');
  //     this.logObject(response.statusText, loggingPrefix + 'statusText ');
  //     this.logObject(response.headers, loggingPrefix + 'headers ');
  //   }
  // }

  /**
   * Log the object if there is a loggingPrefix
   * @param _object
   * @param loggingPrefix
   */
  logObject(_object, loggingPrefix) {
    if (!this) {
      // console.log('THIS UNDEFINED');
    }
    if (!loggingPrefix) {
      return;
    }
    let logging = loggingPrefix + '\n' + (_object ? JSON.stringify(_object, 4, 4) : '(falsey)');
    // console.log(logging);
  }

  /**
   * This is more complexity than I care for, but that's what's specified.
   *
   * So, in the interests of keeping this consistent and testable, all of
   * these functions return promises, and they all check and update the same
   * state, and they all return the same state.
   *
   * Obviously, the 'userState' object is what we're using to track the state
   * through all the promises.
   *
   * The methods here follow similar naming conventions, using pcom as an example...
   * - checkPcom - examines the state to see whether we need to...
   *   - callPcom - a simple http call
   * - handlePcom(userState, response) - if we have a real response, parse the
   *   data out and populate the userState.
   *
   * @param userState
   */
  processUser(userState) {
    this.userInformation = null;
    this.logObject(userState, 'processUser starting userState: ');
    var self = this;
    this.showInputs = false;
    this.fetchMessage = 'Trying to locate your information';
    this.fetching = true;
    this.$timeout();
    this.shouldShowCitizenShip = false;

    this.checkHashApi(userState)
      .then((response) => {
        return this.handleHashResponse(userState, response)
      })
      .then((_userState) => {
        return this.checkAtlasEmplId(userState);
      })
      .then((response) => {
        return this.handleAtlas(userState, response, 'handle Atlas: ');
      })
      .then((_userState) => {
        return this.$q.resolve(_userState);
      })

      .then((_userState) => {
        return this.checkNodeUser(userState);
      })
      .then((response) => {
        return this.handleNodeUser(userState, response, 'nodeUser response ');
      })
      .catch((err) => {
        return this.catchAndRelease(err, userState, 'nodeUser err');
      })

      .then((_userState) => {
        return this.checkAtlasWithPennId(userState);
      })
      .then((response) => {
        return this.handleAtlas(userState, response, 'atlas/pennId response ');
      })
      .catch((err) => {
        return this.catchAndRelease(err, userState, ' atlas/pennId err ')
      })

      .then((_userState) => {
        return this.checkPcom(userState);
      })
      .then((response) => {
        return this.handlePcom(userState, response);
      })
      .catch((err) => {
        return this.catchAndRelease(err, userState, 'ERROR(main): ');
      })

      .then(() => {

        let goodbye = "We're sorry, but we couldn't find your information...";
        if (userState.atlasResponse) {
          goodbye = "We found your information in Alumni Relations!";
          this.userDataService.set(userState.userInformation);
        } else if (userState.nodeUserResponse && userState.pcomResponse) {
          goodbye = "We found your information in Penn Community!";
          this.userDataService.set(userState.userInformation);
        } else if (userState.pcomResponse) {
          goodbye = "We found your information in Penn Community!";
          this.userDataService.set(userState.userInformation);
        }

        this.userInformation = userState.userInformation;
        this.fetchMessage = goodbye;
        this.fetching = false;
        this.$timeout();

        return this.$interval(() => {

        }, 2000, 1)
      })
      .then(() => {
        this.showInputs = true;
        this.fetchMessage = null;
        this.$timeout();

      });
  }


  submit() {

    if(this.shouldShowCitizenShip == true && (this.userInformation.citizenshipType == null || this.userInformation.citizenShipCountryCode == null) ){
      this.shouldShowCitizenShipError = true;
      return false;
    }
    this.submitEnabled = false; // don't let them hit it more than once
    this.checkout.checkout(
      this.csData,
      this.cartMemoryService,
      this.userInformation,
      this.stripeToken,
      this.recaptchaToken,
      null,
      null,
      this.chariotSuccessMetadata,
    );
  }

  // GIVING-279
  // show the spotlight image that goes
  // with the last fund chosen or the default image if there's nothing.
  getImage() {
    return this.$sessionStorage.backgroundImage;
  }

  /**
   * Should we use Stripe to check out?
   */
  useStripeCheckout() {
    return this.UtilService.useStripeCheckout(this.cartMemoryService.cartContents, this.userInformation);
  }

  isStripeCart() {
    return this.UtilService.isStripeCart(this.cartMemoryService.cartContents);
  }

  stripeSubmit(token) {
    this.stripeToken = token;
    this.submit();
  }

  /**
   * @param {StripePaymentMethod} stripePaymentMethod
   * @param {StripePaymentIntent} stripePaymentIntent
   */
  stripeSubmitPaymentRequest(
    stripePaymentMethod,
    stripePaymentIntent
  ) {
    this.submitEnabled = false; // don't let them hit it more than once
    this.checkout.checkout(
      this.csData,
      this.cartMemoryService,
      this.userInformation,
      null,
      null,
      stripePaymentMethod,
      stripePaymentIntent,
      null
    );
  }

  getCartCyberSourceAmount() {
    return this.cartMemoryService.getTotals().cyberSourceAmount;
  }

  getStripePaymentIntent(
    amount,
    currency,
  ) {
    return this.givingService.stripePaymentIntent(amount, currency, this.recaptchaToken);
  }

  /**
   * Should we check out with Chariot?
   * @return {boolean}
   */
  useChariotCheckout() {
    return this.cartMemoryService.status().donorAdvisedFund;
  }

  /**
   * Handles the submission logic for Chariot.
   * @param {ChariotSuccessMetadata} successMetadata
   */
  chariotSubmit(successMetadata) {
    this.chariotSuccessMetadata = successMetadata;
    this.submit();
  }

  showCitizenshipError() {
    this.shouldShowCitizenShipError = true;
  }

}


export default angular.module('givingApp.personal-details', [uiRouter, recaptcha, chariot])
  .config(routes)
  .component('personalDetails', {
    template: require('./personal-details.html'),
    controller: PersonalDetailsComponent
  }).name;
