'use strict';
const angular = require('angular');

/**
 * @const
 * @type {RegExp}
 */


/**
 * I'm just going to do this with three regex's that I understand rather
 * than a complex one from the web I don't.
 */
const DECIMAL_NO_COMMAS = /^\d+\.?\d*$/;
const DECIMAL_WITH_COMMAS = /^\d{1,3}(,\d{3})*(\.\d*)?$/;
const DECIMAL_WITH_CENTS_ONLY = /^\.\d+?$/;


export default angular.module('givingApp.givingCurrency', [])
  .directive('givingCurrency', function () {
    return {
      restrict: 'A',
      scope: {
        minAmount: '<'
      },
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {


        ctrl.$validators.givingCurrencyFormat = function (modelValue, viewValue) {
          // nothing is okay
          if (!viewValue) {
            return true;
          }

          return DECIMAL_NO_COMMAS.test(viewValue)
            || DECIMAL_WITH_COMMAS.test(viewValue)
            || DECIMAL_WITH_CENTS_ONLY.test(viewValue);

        };


        ctrl.$validators.givingCurrencyAmount = function (modelValue, viewValue) {

          // If the format is invalid, this is valid
          if (!ctrl.$validators.givingCurrencyFormat(modelValue, viewValue)) {
            return true;
          }

          // nothing is okay
          if (!viewValue) {
            return true;
          }

          let money = parseFloat(viewValue.replace(/,/g, ''));
          return money >= scope.minAmount;
        };
      }
    };
  })
  .name;
