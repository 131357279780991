'use strict';
import angular from 'angular';

export class fundHighlightComponent {


  /**
   *  Component input.
   *  @type {!DonationGiftingTile}
   */
  fund;

  dataLoader;
  util;

  /*@ngInject*/
  constructor(dataLoader, Util, $location) {
    this.dataLoader = dataLoader;
    this.util = Util;
    this.$location = $location;
  }

  getType() {
    // Logic as per GIVING-92
    if (this.fund.school_code && this.fund.fund_code) {
      return 'FUND';
    }
    if (this.fund.school_code && !this.fund.fund_code) {
      return 'PROGRAM';
    }
    if (this.fund.image_url && this.fund.image_url.startsWith('/find-fund?area=')) {
      return 'AREA';
    }
    if (this.fund.image_url && !this.fund.image_url.startsWith('/find-fund/area=')) {
      return 'EXTERNAL';
    }
  }

  locateArea() {
    let area = this.fund.image_url;
    this.$location.url(area);
  }


  $onInit() {
    // console.log('initting fund-highlight ');
    if (this.fund.school_code && !this.fund.group_code) {
      let school = this.dataLoader.findSchoolBySchoolCode(this.fund.school_code);
      this.fund.group_code = school.group_code;
      // console.log('this.fund.group_code = ' + this.fund.group_code)
    }
    this.fund.image = this.util.getImageUrlMobileOrDesktop(this.fund.mobile_image_file, this.fund.desktop_image_file);
    this.fund.type = this.getType();
    // console.log(`fund.image_url=${this.fund.image_url}, fund.type=${this.fund.type}`);
    this.fund.link = this.fund.image_url;
    //console.log(JSON.stringify(this.fund, 4, 4));
  }
}

export default angular.module('givingApp.fund-highlight', [])
  .component('fundHighlight', {
    template: require('./fund-highlight.html'),
    bindings: {
      fund: '<'
    },
    controller: fundHighlightComponent
  })
  .name;
