import angular from "angular";
import Decimal from 'decimal.js';

/**
 * @param $q
 * @param $element
 * @param simpleModal {SimpleModal}
 * @constructor
 */
export function ChariotController(
  $q,
  $element,
  simpleModal,
) {

  /** @type {string} */
  this.connectId = undefined;

  /** @type {boolean} */
  this.formInvalid = undefined;

  /** @type {string} */
  this.firstAndMiddleName = undefined;

  /** @type {string} */
  this.lastName = undefined;

  /** @type {number} */
  this.amount = undefined;

  /** @type {string} */
  this.email = undefined;

  /** @type {function} */
  this.submit = undefined;

  /** @type {?string} */
  this.citizenshipCountryCode = undefined;

  /** @type {function} */
  this.showCitizenshipError = undefined;

  /** @type {boolean} */
  this.recaptchaReady = undefined;

  /** @type {function} */
  this.onChariotExit = undefined;

  this.success = false;

  this.$onInit = () => {
    const chariot = $element[0].querySelector('#chariot');
    chariot.onDonationRequest(() => {
      if (this.formInvalid || !this.recaptchaReady || this.citizenshipCountryCode === null) {
        let options = {
          header: 'Fill In Missing Fields or Recaptcha',
          message: '<p>Please fill in missing fields or recaptcha</p>',
        };
        simpleModal.showAlert(options);
        if (this.citizenshipCountryCode === null) {
          this.showCitizenshipError();
        }
        return $q.resolve(false)
      }
      if (this.success) {
        // Don't let them hit it again
        return false;
      }
      return $q.resolve({
        firstName: this.firstAndMiddleName,
        lastName: this.lastName,
        amount: new Decimal(this.amount).times(100).round().toNumber(),
        email: this.email,
      });
    });

    chariot.addEventListener('CHARIOT_SUCCESS', ({ /** @type {ChariotSuccessMetadata} */ detail}) => {
      if (!this.recaptchaReady) {
        let options = {
          header: 'Recaptcha Expired',
          message: '<p>Recaptcha expired, please try again</p>',
        };
        simpleModal.showAlert(options);
      } else {
        this.submit({successMetadata: detail});
        this.success = true;
      }
    });

    chariot.addEventListener('CHARIOT_EXIT', () => {
      this.success = false;
      this.onChariotExit();
    });
  }
}

ChariotController.$inject = ['$q', '$element', 'simpleModal'];

export default angular.module('givingApp.chariot', [])
  .component('chariot', {
    template: require('./chariot.html'),
    bindings: {
      connectId: '<',
      formInvalid: '<',
      firstAndMiddleName: '<',
      lastName: '<',
      amount: '<',
      email: '<',
      submit: '&',
      citizenshipCountryCode: '<',
      showCitizenshipError: '&',
      onChariotExit: '&',
      recaptchaReady: '<',
    },
    controller: ChariotController
  })
  .name;
