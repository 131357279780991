'use strict';
const angular = require('angular');

export class RecaptchaController {

  recaptchaId;

  grecaptcha;

  recaptchaSitekey;

  recaptchaCallback;

  recaptchaExpiredCallback;

  setWidgetId;

  recaptchaInitted = false;

  $scope;

  $window;

  /** @ngInject */
  constructor(
    $scope,
    $window) {
    this.$scope = $scope;
    this.$window = $window;
  }

  localRecaptchaCallback = (response) => {
    this.recaptchaCallback({recaptchaToken: response})
    this.$scope.$apply(); // grecaptcha callbacks don't trigger apply so do it manually
  };

  localRecaptchaExpiredCallback = () => {
    this.recaptchaExpiredCallback();
    this.$scope.$apply(); // grecaptcha callbacks don't trigger apply so do it manually
  };

  $doCheck() {
    // Wait for grecaptcha and the recaptcha div to be ready and then render it
    let recaptchaElement = this.$window.document.getElementById(this.recaptchaId);
    if (recaptchaElement && this.grecaptcha && !this.recaptchaInitted) {
      this.recaptchaInitted = true;
      this.recaptchaExpiredCallback(); // Clear out any old token
      let widgetId = this.grecaptcha.render(
        this.recaptchaId,
        {
          'callback': this.localRecaptchaCallback,
          'sitekey': this.recaptchaSitekey,
          'expired-callback': this.localRecaptchaExpiredCallback,
          'size': this.$window.innerWidth < 576 ? 'compact' : 'normal',
        });
      this.setWidgetId({id: widgetId});
    }
  }
}

export default angular.module('wdayreallocationApp.recaptcha', [])
  .component('recaptcha', {
    template: require('./recaptcha.html'),
    bindings: {
      recaptchaId: '@',
      recaptchaSitekey: '<',
      grecaptcha: '<',
      recaptchaCallback: '&',
      recaptchaExpiredCallback: '&',
      setWidgetId: '&',
    },
    controller: RecaptchaController
  })
  .name;
