'use strict';
import angular from 'angular';

/*@ngInject*/
export function frequencyLabelFilter(dataLoader, cartMemory) {

  return function (id, controlTableParam) {
    let controlParam =
      dataLoader
        .getControlParam(controlTableParam, cartMemory.freqNumberToCharCode(id));
    let name = '';
    if (controlParam) {
      name = controlParam.description;
    } else if (id === 3) {
      name = 'Once'
    }

    return `${name}`;
  };
}

export default angular.module('givingApp.frequencyLabelFilter', [])
  .filter('frequencyLabel', frequencyLabelFilter)
  .name;
