/**
 * #Created with WebStorm.
 * User: mattschleindl
 * Date: 11/21/16
 * Time: 3:39 PM
 * To change this template use File | Settings | File Templates.
 */
'use strict';
const angular = require('angular');
// import './simpleModal.scss';

export class SimpleModal {
  /*@ngInject*/
  constructor($rootScope, $uibModal) {
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
  }
  message = 'Not set';

  /**
   *
   * @param options
   * @param modalScope
   * @private
   */
  _openModal(options, modalScope) {
    return this.$uibModal.open({
      scope: modalScope,
      windowClass: options.windowClass || 'simpleModalCssClass',
      animation: true,
      size: options.size,
      keyboard: false, // suppress the user's ability to dismiss the modal without clicking a button
      template: require('./simpleModalTemplate.html')
    });
  }

  /**
   * Execute the callback if it has been supplied, if the result is falsey that is an error
   * @param callback
   * @param result button associated with the button click, eg 'ok','yes','no' or 'cancel'
   * @returns {*}
   * @private
   */
  static _doCallback(callback, result) {
    if (callback) {
      var err = result ? null : new Error("'result' is empty");
      callback(err, result);
    }
    return result;
  }


  /**
   * Show an alert / modal dialog with an 'Ok' button.
   *
   * @param options are an object with the following parameters
   * message: String, markup is not currently rendered although if its important enough we could
   * header: String
   * size: falsey or one of 'xs','sm','md','lg'
   * callback: optional function(err, data) where data will be the lower case value of the button clicked, 'ok','yes','no','cancel'
   * okText: custom text for the ok button, default is 'Ok' (callback will still return 'ok' when clicked)
   * cancelText: custom text for cancel
   * yesText: same
   * noText: same
\  */
  showAlert(options) {
    var modalScope = this.$rootScope.$new();
    var modal = this._openModal(options, modalScope);

    modalScope.message = options.message;
    modalScope.header = options.header;

    modalScope.okText = options.okText || 'Ok';
    modalScope.ok = function () {
      modal.dismiss('ok');
      SimpleModal._doCallback(options.callback, 'ok');
    };
  }

  // For testing - gives us a way to call
  // the ok/cancel/yes/no functions
  modalScope;

  showOkCancel(options) {
    var modalScope = this.$rootScope.$new();
    this.modalScope = modalScope;
    var modal = this._openModal(options, modalScope);

    modalScope.message = options.message;
    modalScope.header = options.header;

    modalScope.okText = options.okText || 'Ok';
    modalScope.ok = function () {
      modal.dismiss('ok');
      SimpleModal._doCallback(options.callback, 'ok');
    };

    modalScope.cancelText = options.cancelText || 'Cancel';
    modalScope.cancel = function () {
      modal.dismiss('cancel');
      SimpleModal._doCallback(options.callback, 'cancel');
    };
  }

  showYesNoCancel(options) {
    var modalScope = this.$rootScope.$new();
    var modal = this._openModal(options, modalScope);

    modalScope.message = options.message;
    modalScope.header = options.header;

    modalScope.yesText = options.yesText || 'Yes';
    modalScope.yes = function () {
      modal.dismiss('yes');
      SimpleModal._doCallback(options.callback, 'yes');
    };

    modalScope.noText = options.noText || 'No';
    modalScope.no = function () {
      modal.dismiss('no');
      SimpleModal._doCallback(options.callback, 'no');
    };

    modalScope.cancelText = options.cancelText || 'Cancel';
    modalScope.cancel = function () {
      modal.dismiss('cancel');
      SimpleModal._doCallback(options.callback, 'cancel');
    };
  }
}

export default angular
  .module('givingApp.simpleModal', [])
  .service('simpleModal', SimpleModal)
  .name;
