'use strict';
const angular = require('angular');

export class searchFundComponent {
  constructor(dataLoader, $location) {
    'ngInject'
    this.dataLoader = dataLoader;
    this.$location = $location;

    this.funds = dataLoader.funds;
    this.selected = undefined;
    // what the user has typed in
    this.searchString = '';

    //ms: this appears only in the distibuted demo (under node_modules/angular-ui-bootstrap/src/typeahead/docs), not needed for customTemplate
    this._selected = undefined;

    //ms: this appears only in the distibuted demo (under node_modules/angular-ui-bootstrap/src/typeahead/docs), not needed for customTemplate
    this.modelOptions = {
      debounce: {
        default: 500,
        blur: 250
      },
      getterSetter: true
    };
  }

  findFunds(substring) {
    this.searchString = substring;
    let results = [{fund_name: 'View All', hibernate_id: null}];
    // list of funds matching searchString
    //this.dataLoader.selectedFunds = [];
    results = this.dataLoader.findMatchingFunds(substring, results);

    return results.length > 1 ? results : [];
  }

  /**
   * If 'View All', hibernate_id will be undefined
   *
   * @param item
   * @returns {string}
   */
  getSelectedUrl(item) {
    if(item.hibernate_id) {
      // probably isn't necessary to do this since we are sending item data via URL, but keeps it clean
      this.dataLoader.selectedFunds = [item];
      return '/fund?program=' + item.school_code + '&fund=' + item.fund_code;
    } else {
      // note that this.dataLoader.selectedFunds contains the list of selected funds
      return '/search/' + this.searchString;
    }
  }

  selectedResult(item) {
    this.selected = item;
    var selectedUrl = this.getSelectedUrl(item);
    // console.log('selected url = ' + selectedUrl);
    this.$location.url(selectedUrl);
  }
}

export default angular.module('givingApp.searchFund', [])
  .component('searchFund', {
    template: require('./searchFund.html'),
    controller: searchFundComponent,
  })
  .name;
