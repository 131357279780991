'use strict';
import angular from 'angular';

export class heroImageComponent {
  /*@ngInject*/
  constructor() {

  }
}

export default angular.module('givingApp.hero-image', [])
  .component('heroImage', {
    template: require('./hero-image.html'),
    bindings: {
        image: '<',
        getText: '&',
        getUrl: '&',
        paramsLoaded: '&',
    },
    controller: heroImageComponent
  })
  .name;
