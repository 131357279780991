'use strict';

/**
 * From the /amount service.
 *
 * Setting all fields to null to aid in testing - make sure
 * json has correct fields when copied over.
 */
export default class Amount {

  /** @type {?number} */
  hibernate_id = null;

  /** @type {?string} */
  fund_code = null;

  /** @type {?string} */
  school_code = null;

  /** @type {?string} */
  description = null;

  /** @type {?number} */
  amount = null;

  /** @type {?number} */
  order_no = null;

  /** @type {?number} */
  sequence_no = null;

}
