'use strict';

const angular = require('angular');

/*@ngInject*/
export function currencyNonzeroCentsFilter() {

  /**
   * 100.00, '$' becomes "$100"
   * 100.09, '$' becomes "$100.09"
   * @param {number} amount
   * @param {string} currencySymbol
   * @return currencySymbol + formmatted_amount
   */
  return function (amount, currencySymbol) {
    amount = parseFloat(amount);
    amount = amount.toFixed(amount % 1 === 0 ? 0 : 2);
    return currencySymbol + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
}

export default angular.module('givingApp.currencyNonzeroCentsFilter', [])
  .filter('currencyNonzeroCents', currencyNonzeroCentsFilter)
  .name;
