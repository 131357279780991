import angular from 'angular';

export class FooterComponent {

  // ADR-394
  getFullYear() {
    return new Date().getFullYear()
  }
}

export default angular.module('directives.footer', [])
  .component('footer', {
    template: require('./footer.html'),
    controller: FooterComponent
  })
  .name;
