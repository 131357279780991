'use strict';

import angular from 'angular';

export class userDataService {

    constructor($sessionStorage) {
        'ngInject';
        this.sessionStorage = $sessionStorage;
        
        if (angular.isDefined(this.sessionStorage.userInformation)) {
            this.userInformation = this.sessionStorage.userInformation;
        } else {
            this.userInformation = {};
        }
    }

    get = () => {
        if (this.sessionStorage.userInformation) {
            return this.userInformation;
        } else {
            return false;
        }
    }

    set = (data) => {
        this.userInformation = data;
        this.sessionStorage.userInformation = data;
    }

}

export default angular.module('givingApp.userData', []).service('userData', userDataService).name;
