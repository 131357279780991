'use strict';
const angular = require('angular');


export class GiftOptionsController {


  formData;

  /** @ngInject */
  constructor($interval) {
    this.$interval = $interval;
  }

  $onInit() {
  }


  //NOTE: Refactor dropdown to work better with multiple on a pages... this is daft! High prioity! Grant Bajere
  dropdownSelected(item, model) {

    if (model === 'memory') {
      if (angular.isDefined(this.formData.memory) && angular.isDefined(this.formData.memory.aditionalInformation)) {
        this.formData.memory.aditionalInformation.title = item.name;
      } else {
        this.formData.memory = {
          "aditionalInformation": {
            title: item.name
          }
        };
      }
    }

    if (model === 'memoryAck') {
      if (angular.isDefined(this.formData.memory) && angular.isDefined(this.formData.memory.aditionalInformation)) {
        this.formData.memory.aditionalInformation.ackTitle = item.name;
      } else {
        this.formData.memory = {
          "aditionalInformation": {
            ackTitle: item.name
          }
        };
      }
    }

    if (model === 'memory-country') {
      if (angular.isDefined(this.formData.memory) && angular.isDefined(this.formData.memory.aditionalInformation)) {
        this.formData.memory.aditionalInformation.address.country = item.name;
      } else {
        this.formData.memory = {
          "aditionalInformation": {
            "address": {
              "country": item.name
            }
          }
        };
      }
    }

    if (model === 'honor') {
      if (angular.isDefined(this.formData.honor) && angular.isDefined(this.formData.honor.aditionalInformation)) {
        this.formData.honor.aditionalInformation.title = item.name;
      } else {
        this.formData.honor = {
          "aditionalInformation": {
            title: item.name
          }
        };
      }
    }

    if (model === 'honor-country') {
      if (angular.isDefined(this.formData.honor) && angular.isDefined(this.formData.honor.aditionalInformation)) {
        this.formData.honor.aditionalInformation.address.country = item.name;
      } else {
        this.formData.honor = {
          "aditionalInformation": {
            "address": {
              "country": item.name
            }
          }
        };
      }
    }
  };

  specialProcessing() {
    return this.formData.special_processing === 'T'
  }

}

export default angular.module('givingApp.giftOptions', [])
  .component('giftOptions', {
    template: require('./giftOptions.html'),
    bindings: {
      formData: '<', // one-way binding, but this will be modified in this component
      schoolRecord: '<',
      countries: '<',
      fundData: '<',
      fastStart: '<',
      titles: '<'
    },
    controller: GiftOptionsController
  })
  .name;
