'use strict';

export default function ($stateProvider) {
  'ngInject';
  $stateProvider
    .state('thank-you', {
      url: '/thank-you?fastStart&req_reference_number&auth_code&auth_trans_ref_no&reason_code&req_card_type',
      params: {
        fastStart: null
      },
      template: '<thank-you></thank-you>',
      resolve: {
        'DataLoader': function (dataLoader) {
          // users will be coming in from an outside page so make sure caches are loaded
          // GIVING-492: don't need funds, don't pull them back over network
          return dataLoader.loadCachesNoFunds();
        }
      },
      onEnter: (cartMemory, $sessionStorage, $state) => {
        if ($sessionStorage.thankYouStateForReload
          || $sessionStorage.donationSubmitted) {
          // we have some reason to load the thank you page
        } else {
          // we have no business on this page
          $state.go('find-fund');
        }
      }

    });
}
