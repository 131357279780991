'use strict';
/* eslint no-sync: 0 */

import angular from 'angular';

export class HelpbarComponent {
    constructor($rootScope) {
        'ngInject';
    }

    toggleHelp = () => {
        if (this.showHelp === true) {
            this.showHelp = false;
        } else {
            this.showHelp = true;
        }
    }

    $onInit() {
        this.showHelp = false;
        // console.log('initting helpbar');
    }
}

export default angular.module('directives.helpbar', []).component('helpbar', {
    template: require('./helpbar.html'),
    controller: HelpbarComponent
}).name;
