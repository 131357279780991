'use strict';
const angular = require('angular');

export class navDropdownComponent {
  /*@ngInject*/
  constructor() {
    // you can programmatically build menus...
    // this.items = [
    //   'The first choice!',
    //   'And another choice for you.',
    //   'but wait! A third!'
    // ];

    this.text = 'Menu';

    this.status = {
      isopen: false
    };

  }
}

export default angular.module('afs41App.navDropdown', [])
  .component('navDropdown', {
    template: require('./navDropdown.html'),
    transclude: true,
    bindings: {
      text: '@'
    },
    controller: navDropdownComponent
  })
  .name;
