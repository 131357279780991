'use strict';
import angular from 'angular';

export class modalComponent {

  /**
   * Whatever we binded resolve to. And then access your
   * object like resolve.thing
   *
   * @type {!Object} */
  resolve;

  constructor(Util) {
    'ngInject';
    this.util = Util;
  }

  $onInit() {

    this.closeModal = function () {
      //`this` is effectivly $uibModalInstance created by $uibModal open call
      this.close();
    };

  }

  getImage() {
    let imageUrl =
      this.util.getImageUrlMobileOrDesktop(
        this.resolve.spotlightFund.mobile_image_file,
        this.resolve.spotlightFund.image_file
      );
    return imageUrl;
  }

}

export default angular.module('givingApp.modal', []).component('modal', {
  template: require('./modal.html'),
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: modalComponent
}).name;
