'use strict';
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routes from './search.routes';

export class SearchComponent {

  data;

    constructor($http, $stateParams, $window, dataLoader) {
        'ngInject';
        this.$http = $http;
        this.$stateParams = $stateParams;
        this.$window = $window;
        this.dataLoader = dataLoader;
    }

    backToTop = () => {
        this.$window.scrollTo(0, 0);
    };

    $onInit() {
      this.searchTerm = this.$stateParams.term;
      this.dataLoader.selectedFunds = this.dataLoader.findMatchingFunds(this.searchTerm);
      this.funds = this.dataLoader.selectedFunds;
    }
}

export default angular.module('givingApp.search', [uiRouter])
.config(routes)
.component('search', {
    template: require('./search.html'),
    controller: SearchComponent
}).name;
